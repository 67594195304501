import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import OtziviArrow from "../../elements/OtziviArrow/OtziviArrow";
import { flushSync } from "react-dom";
import { useNavigate } from "react-router-dom";
import TelegramSvg from "../../elements/TelegramSvg/TelegramSvg";

const MainPageModuleSeven = () => {
  const navigate = useNavigate()
  const slide_1 = useRef();
  const dots1 = useRef();
  const dots2 = useRef();
  const dots3 = useRef();
  const dots4 = useRef();
  const slide_2 = useRef();
  const slide_3 = useRef();
  const slide_4 = useRef();
  const slide_1m = useRef();
  const dots1m = useRef();
  const dots2m = useRef();
  const dots3m = useRef();
  const dots4m = useRef();
  const slide_2m = useRef();
  const slide_3m = useRef();
  const slide_4m = useRef();
  const leftArrow = useRef();
  const rightArrow = useRef();
  const leftArrowm = useRef();
  const rightArrowm = useRef();
  const [slideIndex, setSlideIndex] = useState(1);

  useEffect(() => {
    if (slideIndex === 1) {
      leftArrow.current.style.opacity = "0.2";
      slide_1.current.style.display = "flex";
      slide_2.current.style.display = "none";
      slide_3.current.style.display = "none";
      slide_4.current.style.display = "none";
      dots2.current.style.background = "#D9D9D9";
      dots3.current.style.background = "#D9D9D9";

      dots4.current.style.background = "#D9D9D9";
      dots1.current.style.background = "#007AFF";
    }
    if (slideIndex === 2) {
      slide_1.current.style.display = "none";
      slide_2.current.style.display = "flex";
      slide_3.current.style.display = "none";
      slide_4.current.style.display = "none";
      dots1.current.style.background = "#D9D9D9";
      dots3.current.style.background = "#D9D9D9";

      dots4.current.style.background = "#D9D9D9";
      dots2.current.style.background = "#007AFF";
    }
    if (slideIndex === 3) {
      slide_1.current.style.display = "none";
      slide_2.current.style.display = "none";
      slide_3.current.style.display = "flex";
      slide_4.current.style.display = "none";
      dots1.current.style.background = "#D9D9D9";
      dots2.current.style.background = "#D9D9D9";

      dots4.current.style.background = "#D9D9D9";
      dots3.current.style.background = "#007AFF";
    }
    if (slideIndex !== 1) {
      leftArrow.current.style.opacity = "1";
    }
    if (slideIndex === 4) {
      rightArrow.current.style.opacity = "0.2";
      slide_1.current.style.display = "none";
      slide_2.current.style.display = "none";
      slide_3.current.style.display = "none";
      slide_4.current.style.display = "flex";
      dots1.current.style.background = "#D9D9D9";
      dots2.current.style.background = "#D9D9D9";
      dots3.current.style.background = "#D9D9D9";

      dots4.current.style.background = "#007AFF";
    }
    if (slideIndex !== 4) {
      rightArrow.current.style.opacity = "1";
    }


    if (slideIndex === 1) {
      leftArrowm.current.style.opacity = "0.2";
      slide_1m.current.style.display = "flex";
      slide_2m.current.style.display = "none";
      slide_3m.current.style.display = "none";
      slide_4m.current.style.display = "none";
      dots2m.current.style.background = "#D9D9D9";
      dots3m.current.style.background = "#D9D9D9";

      dots4m.current.style.background = "#D9D9D9";
      dots1m.current.style.background = "#007AFF";
    }
    if (slideIndex === 2) {
      slide_1m.current.style.display = "none";
      slide_2m.current.style.display = "flex";
      slide_3m.current.style.display = "none";
      slide_4m.current.style.display = "none";
      dots1m.current.style.background = "#D9D9D9";
      dots3m.current.style.background = "#D9D9D9";

      dots4m.current.style.background = "#D9D9D9";
      dots2m.current.style.background = "#007AFF";
    }
    if (slideIndex === 3) {
      slide_1m.current.style.display = "none";
      slide_2m.current.style.display = "none";
      slide_3m.current.style.display = "flex";
      slide_4m.current.style.display = "none";
      dots1m.current.style.background = "#D9D9D9";
      dots2m.current.style.background = "#D9D9D9";

      dots4m.current.style.background = "#D9D9D9";
      dots3m.current.style.background = "#007AFF";
    }
    if (slideIndex !== 1) {
      leftArrowm.current.style.opacity = "1";
    }
    if (slideIndex === 4) {
      rightArrowm.current.style.opacity = "0.2";
      slide_1m.current.style.display = "none";
      slide_2m.current.style.display = "none";
      slide_3m.current.style.display = "none";
      slide_4m.current.style.display = "flex";
      dots1m.current.style.background = "#D9D9D9";
      dots2m.current.style.background = "#D9D9D9";
      dots3m.current.style.background = "#D9D9D9";

      dots4m.current.style.background = "#007AFF";
    }
    if (slideIndex !== 4) {
      rightArrowm.current.style.opacity = "1";
    }



  });

  const leftArrowClick = () => {
    if (slideIndex > 1) {
      setSlideIndex(slideIndex - 1);
    } else {
      setSlideIndex(1);
    }
  };
  const rightArrowClick = () => {
    if (slideIndex < 4) {
      setSlideIndex(slideIndex + 1);
    } else {
      setSlideIndex(4);
    }
  };
  return (
    <div className="module_seven_container mb_container">
      <h2 className="title mb_title">
        Отзывы наших клиентов
      </h2>
      <div className="module_seven_block_mobile">
        <div className="module_seven_block_one">
          <h3 className="title">Нас рекомендуют друзьям и знакомым</h3>
          <p className="text">
            Все отзывы о&nbsp;работе сервиса собраны в&nbsp;телеграм канале. Каждому, кто оставил отзыв можно написать и&nbsp;пообщаться.
          </p>
        </div>
        <div className="module_seven_block_two">
          <div className="module_seven_block_two_slider">
            <div ref={slide_1m} className="module_seven_block_two_slider_item">
              <div className="module_seven_block_two_slider_block">
              <div className="module_seven_block_two_slider_text_wrapper">
                  <p className="module_seven_block_two_slider_text">
                  «Обычно перевожу криптой, но решила попробовать через бота перевод. Забрала в Tonwex и оплату взяли при получении. Быстро, без заморочек, в течение 20 минут уже получила деньги. Классно, что не надо 100500 полей, и еще где-то разбираться, как что работает. Буду теперь только через этот сервис переводить. Спасибо!»
                  </p>
                  <div className="module_seven_block_two_slider_user">
                    <img src="/assets/avatar4.png" alt="img" />

                    <div className="module_seven_block_two_slider_user_block">
                      <span>Катя</span>
                      <span>@gor**********</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="img_back">
                <div className="img_back_box">
                  <img src="/assets/otziv1.png" alt="img" />
                </div>
              </div> */}
            </div>
            <div ref={slide_2m} className="module_seven_block_two_slider_item">
              <div className="module_seven_block_two_slider_block">
              <div className="module_seven_block_two_slider_text_wrapper">
                  <p className="module_seven_block_two_slider_text">
                  «Сделала 3 перевода. В течение 30 минут деньги были отправлены на мой счет с подтверждением об отправке. С небольшой, оговоренной комиссией. Впредь буду пользоваться этой системой. Рекомендую, работают честно!»
                  </p>
                  <div className="module_seven_block_two_slider_user">
                    <img src="/assets/avatar1.png" alt="img" />

                    <div className="module_seven_block_two_slider_user_block">
                      <span>Елена</span>
                      <span>@Onu*******</span>

                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="img_back">
                <div className="img_back_box">
                  <img src="/assets/otziv4.png" alt="img" />
                </div>
              </div> */}
            </div>
            <div ref={slide_3m} className="module_seven_block_two_slider_item">
              <div className="module_seven_block_two_slider_block">
                <div className="module_seven_block_two_slider_text_wrapper">
                  <p className="module_seven_block_two_slider_text">
                    «Спасибо Вам! Не ожидали, что будет все так быстро и удобно! Все переводы будем осуществлять только через Вас!»
                  </p>

                  <div className="module_seven_block_two_slider_user">
                    <img src="/assets/avatar2.png" alt="img" />

                    <div className="module_seven_block_two_slider_user_block">
                      <span>Кристи</span>
                      <span>@gor*****</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="img_back">
                <div className="img_back_box">
                  <img src="/assets/otziv2.png" alt="img" />
                </div>
              </div> */}
            </div>
            <div ref={slide_4m} className="module_seven_block_two_slider_item">
              <div className="module_seven_block_two_slider_block">
                <div className="module_seven_block_two_slider_text_wrapper">
                  <p className="module_seven_block_two_slider_text">
                    «Сегодня получал перевод. Все быстро, без проблем.»
                  </p>
                  <div className="module_seven_block_two_slider_user">
                    <img src="/assets/avatar3.png" alt="img" />

                    <div className="module_seven_block_two_slider_user_block">
                      <span>Александр</span>
                      <span>@san****</span>

                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="img_back">
                <div className="img_back_box">
                  <img src="/assets/otziv3.png" alt="img" />
                </div>
              </div> */}
            </div>

            <div className="module_seven_block_two_slider_manag">
              <div className="module_seven_block_two_slider_arrows">
                <div
                  onClick={leftArrowClick}
                  ref={leftArrowm}
                  className="module_seven_block_two_slider_left_arrow"
                >
                  <OtziviArrow />
                </div>
                <div className="dots_module">
                  <div
                    onClick={() => setSlideIndex(1)}
                    ref={dots1m}
                    className="dots"
                  ></div>
                  <div
                    onClick={() => setSlideIndex(2)}
                    ref={dots2m}
                    className="dots"
                  ></div>
                  <div
                    onClick={() => setSlideIndex(3)}
                    ref={dots3m}
                    className="dots"
                  ></div>
                  <div
                    onClick={() => setSlideIndex(4)}
                    ref={dots4m}
                    className="dots"
                  ></div>
                </div>
                <div
                  onClick={rightArrowClick}
                  ref={rightArrowm}
                  className="module_seven_block_two_slider_right_arrow"
                >
                  <OtziviArrow />
                </div>
              </div>
            </div>
          </div>
        </div>
        <form action="https://t.me/transferdeliveryotziv">
          <button className="btn module_seven_btn"
          ><TelegramSvg />
            Отзывы TransferDelivery</button>
        </form>

      </div >
      <div className="module_seven_block">
        <div className="module_seven_block_one">
          <h2 className="title">Нас{'\u00A0'}рекомендуют друзьям и{'\u00A0'}знакомым</h2>
          <p className="text">
            Все отзывы о&nbsp;работе нашего сервиса собраны в&nbsp;телеграм-канале. Это
            клиенты, которым можно написать и&nbsp;с&nbsp;которыми можно пообщаться
          </p>
          <form action="https://t.me/transferdeliveryotziv">
            <button className="btn module_seven_btn"
            > <TelegramSvg />
              Отзывы TransferDelivery</button>
          </form>
        </div>
        <div className="module_seven_block_two">
          <div className="module_seven_block_two_slider">
            <div ref={slide_1} className="module_seven_block_two_slider_item">
              <div className="module_seven_block_two_slider_block">
                <div className="module_seven_block_two_slider_text_wrapper">
                  <p className="module_seven_block_two_slider_text">
                    «Обычно перевожу криптой, но решила попробовать через бота перевод. Забрала в Tonwex и оплату взяли при получении. Быстро, без заморочек, в течение 20 минут уже получила деньги. Классно, что не надо 100500 полей, и еще где-то разбираться, как что работает. Буду теперь только через этот сервис переводить. Спасибо!»
                  </p>
                  <div className="module_seven_block_two_slider_user">
                    <img src="/assets/avatar4.png" alt="img" />

                    <div className="module_seven_block_two_slider_user_block">
                      <span>Катя</span>
                      <span>@gor**********</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="img_back">
                <div className="img_back_box">
                  <img src="/assets/otziv1.png" alt="img" />
                </div>
              </div> */}
            </div>
            <div ref={slide_2} className="module_seven_block_two_slider_item">
              <div className="module_seven_block_two_slider_block">
                <div className="module_seven_block_two_slider_text_wrapper">
                  <p className="module_seven_block_two_slider_text">
                    «Сделала 3 перевода. В течение 30 минут деньги были отправлены на мой счет с подтверждением об отправке. С небольшой, оговоренной комиссией. Впредь буду пользоваться этой системой. Рекомендую, работают честно!»
                  </p>
                  <div className="module_seven_block_two_slider_user">
                    <img src="/assets/avatar1.png" alt="img" />

                    <div className="module_seven_block_two_slider_user_block">
                      <span>Елена</span>
                      <span>@Onu*******</span>

                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="img_back">
                <div className="img_back_box">
                  <img src="/assets/otziv4.png" alt="img" />
                </div>
              </div> */}


            </div>
            <div ref={slide_3} className="module_seven_block_two_slider_item">
              <div className="module_seven_block_two_slider_block">
                <div className="module_seven_block_two_slider_text_wrapper">
                  <p className="module_seven_block_two_slider_text">
                    «Спасибо Вам! Не ожидали, что будет все так быстро и удобно! Все переводы будем осуществлять только через Вас!»
                  </p>

                  <div className="module_seven_block_two_slider_user">
                    <img src="/assets/avatar2.png" alt="img" />

                    <div className="module_seven_block_two_slider_user_block">
                      <span>Кристи</span>
                      <span>@gor*****</span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="img_back">
                <div className="img_back_box">
                  <img src="/assets/otziv2.png" alt="img" />
                </div>
              </div> */}

            </div>
            <div ref={slide_4} className="module_seven_block_two_slider_item">
              <div className="module_seven_block_two_slider_block">
                <div className="module_seven_block_two_slider_text_wrapper">
                  <p className="module_seven_block_two_slider_text">
                    «Сегодня получал перевод. Все быстро, без проблем.»
                  </p>
                  <div className="module_seven_block_two_slider_user">
                    <img src="/assets/avatar3.png" alt="img" />

                    <div className="module_seven_block_two_slider_user_block">
                      <span>Александр</span>
                      <span>@san****</span>

                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="img_back">
                <div className="img_back_box">
                  <img src="/assets/otziv3.png" alt="img" />
                </div>
              </div> */}

            </div>

            <div className="module_seven_block_two_slider_manag">
              <div className="dots_module">
                <div
                  onClick={() => setSlideIndex(1)}
                  ref={dots1}
                  className="dots"
                ></div>
                <div
                  onClick={() => setSlideIndex(2)}
                  ref={dots2}
                  className="dots"
                ></div>
                <div
                  onClick={() => setSlideIndex(3)}
                  ref={dots3}
                  className="dots"
                ></div>
                <div
                  onClick={() => setSlideIndex(4)}
                  ref={dots4}
                  className="dots"
                ></div>
              </div>
              <div className="module_seven_block_two_slider_arrows">
                <div
                  onClick={leftArrowClick}
                  ref={leftArrow}
                  className="module_seven_block_two_slider_left_arrow"
                >
                  <OtziviArrow />
                </div>
                <div
                  onClick={rightArrowClick}
                  ref={rightArrow}
                  className="module_seven_block_two_slider_right_arrow"
                >
                  <OtziviArrow />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default MainPageModuleSeven;
