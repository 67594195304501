import React from "react";

const TelegramSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      className="teleg_svg"
    >
      <path
        d="M14.7011 1.08151L0.525852 7.60944C-0.0445851 7.91344 -0.237523 8.52221 0.387977 8.85261L4.02454 10.2327L12.8173 3.74327C13.2974 3.33587 13.7889 3.44451 13.3659 3.89267L5.81416 12.0582L5.57694 15.5139C5.79666 16.0475 6.19897 16.0499 6.4556 15.7847L8.54491 13.4239L12.1232 16.6237C12.9543 17.2113 13.4065 16.8321 13.5853 15.7552L15.9323 2.48332C16.176 1.15769 15.7605 0.573607 14.7011 1.08151Z"
        fill="white"
      />
    </svg>
  );
};

export default TelegramSvg;
