import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import SelectArrowSvg from "../SelectArrowSvg/SelectArrowSvg";
import ArrowSvg from "../ArrowSvg/ArrowSvg";
import { getCountries, getCountriesProperties } from "../../http/transactionAPI";
import { Countries, Currencies, IsOpenCountry, IsOpenCurrency, IsOpenMethod, IsOpenSendCountry, IsOpenSendCurrency, SendCountries, SendCurrencies } from "../..";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const SelectSendCurrency = ({redSendCurrenxy}) => {
  const [country, setCountry] = useContext(Countries);
  const [sendCurrency, setSendCurrency] = useContext(SendCurrencies);
  const ref = useRef()

  const [title, setTitle] = useState("Валюта получения");
  const [isOpenSendCountry, setIsOpenSendCountry] = useContext(IsOpenSendCountry);
  const [isOpenMethod, setIsOpenMethod] = useContext(IsOpenMethod);

  const [isOpenSendCurrency, setIsOpenSendCurrency] = useContext(IsOpenSendCurrency);
  const [isOpenCountry, setIsOpenCountry] = useContext(IsOpenCountry);
  const [isOpenCurrency, setIsOpenCurrency] = useContext(IsOpenCurrency);

  const [array, setArray] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const currencies = [];
  const currency_name = [];
  useEffect(() => {
    setIsLoad(true)
    if (country !== "Выберите страну получения")
      getCountriesProperties(country.title).then((response) => {
        response.country.currencies.forEach((item) => {
          currencies.push(item);
        });
        currencies.forEach((item) => {
          currency_name.push(item.title);
          setArray(currency_name)
        });
        setIsLoad(false)
      });
  }, [country])

  useEffect(() => {
    // if (isOpenCountry) setIsOpenSendCurrency(false);

    // if (isOpenSendCountry) setIsOpenSendCurrency(false);
    // if (isOpenMethod) setIsOpenSendCurrency(false);
    // if (isOpenCurrency) setIsOpenSendCurrency(false);
    if (isOpenSendCurrency) setIsOpenSendCountry(false);
    if (isOpenSendCurrency) setIsOpenMethod(false);
    if (isOpenSendCurrency) setIsOpenCurrency(false);
    if (isOpenSendCurrency) setIsOpenCountry(false);
    if (redSendCurrenxy) {
      ref.current.style.border = '3px solid red'
    } else {
      ref.current.style.border = 'none'
    }
    if (title !== "Валюта получения") {
      ref.current.style.border = 'none'
   }
  })

  return (
    <div
    ref={ref}
      onClick={country !== 'Выберите страну получения' ?
        isOpenSendCurrency ? () => { setIsOpenSendCurrency(false) } : () => { setIsOpenSendCurrency(true) }
        : () => toast.error("Выберите страну получения", {
          position: toast.POSITION.BOTTOM_RIGHT,
        })}
      className="select_btn_container_currency">
      <div className="select_container__send_currency_title">{title}</div>
      {isOpenSendCurrency ? (
        <div className="select_options_currency">
          {isLoad ? (
            <span className="spinner"></span>
          ) : (
            array.map((item) => {
              if (item === title) {
                return (
                  <div
                    className="select_optionArrow"
                    key={item}
                    onClick={() => { setTitle(item); setSendCurrency(item) }}
                  >
                    <SelectArrowSvg />
                    {item}
                  </div>
                );
              } else {
                return (
                  <div
                    className="select_option"
                    key={item}
                    onClick={() => { setTitle(item); setSendCurrency(item) }}
                  >
                    {item}
                  </div>
                );
              }
            })
          )}
        </div>
      ) : (
        ""
      )}
      <ArrowSvg />
    </div>
  );
};

export default SelectSendCurrency;
