import React, { useContext, useEffect, useRef, useState } from "react";
import SelectArrowSvg from "../SelectArrowSvg/SelectArrowSvg";
import ArrowSvg from "../ArrowSvg/ArrowSvg";
import { getCountries } from "../../http/transactionAPI";
import { Countries, IsOpenCountry, IsOpenCurrency, IsOpenMessenger, IsOpenMethod, IsOpenSendCountry, IsOpenSendCurrency, Messenger } from "../..";
import TelegramSvg from "../TelegramSvg/TelegramSvg";
import WhatsAppSvg from "../WhatsAppSvg/WhatsAppSvg";

const SelectMessenger = () => {
  const [messenger, setMessenger] = useContext(Messenger);
  const [title, setTitle] = useState('Telegram');
  const [isOpenSendCountry, setIsOpenSendCountry] = useContext(IsOpenSendCountry);
  const [isOpenMethod, setIsOpenMethod] = useContext(IsOpenMethod);
  const [isOpenMessenger, setIsOpenMessenger] = useContext(IsOpenMessenger);
  

  const [isOpenSendCurrency, setIsOpenSendCurrency] = useContext(IsOpenSendCurrency);
  const [isOpenCountry, setIsOpenCountry] = useContext(IsOpenCountry);
  const [isOpenCurrency, setIsOpenCurrency] = useContext(IsOpenCurrency);
  const array = ['Telegram', 'WhatsApp']
  
  useEffect(() => {
    // if (isOpenSendCountry) setIsOpenMessenger(false);
    // if (isOpenCountry) setIsOpenMessenger(false);
    // if (isOpenSendCurrency) setIsOpenMessenger(false);
    // if (isOpenMethod) setIsOpenMessenger(false);
    // if (isOpenCurrency) setIsOpenMessenger(false);

    if (isOpenMessenger) setIsOpenSendCurrency(false);
    if (isOpenMessenger) setIsOpenMethod(false);
    if (isOpenMessenger) setIsOpenCurrency(false);
    if (isOpenMessenger) setIsOpenSendCountry(false);
    if (isOpenMessenger) setIsOpenCountry(false);
    
   
  });
  return (
    <div
      onClick={
        isOpenMessenger
          ? () => {
            setIsOpenMessenger(false);
          }
          : () => {
            setIsOpenMessenger(true);
          }
      }
      className="telsvg"
    >
      <div className="select_container__title">
      {messenger === 'Telegram' ?
                  <TelegramSvg/>
                  :<WhatsAppSvg/>}
      </div>
      {isOpenMessenger ? (
        <div className="select_options_currency">
          {
            array.map((item) => {
              // if (item === title) {
              return (
                <div
                  className="select_option"
                  key={item}
                  onClick={() => {
                    if (item === 'Telegram'){
                          setMessenger('Telegram');
                    } else {
                        setMessenger('WhatsApp');

                    }
                  
                    // setMessenger(element);
                  }}
                >
                  {/* <SelectArrowSvg /> */}
                  {item === 'Telegram' ?
                  <TelegramSvg/>
                  :<WhatsAppSvg/>}
                </div>
              );
              // } else {
              //   return (
              //     <div
              //       className="select_option"
              //       key={item}
              //       onClick={() => {
              //         setTitle(item);
              //        array1.forEach((element) => {
              //           if (item === element.title_rus) {
              //             setCountry(element.title);
              //             setFlag(element.title)

              //           }
              //         });
              //       }}
              //     >
              //       <img src={`/assets/flags/${flag}.png`}/>
              //       {item}
              //     </div>
              //   );
              // }
            })
         }
        </div>
      ) : (
        ""
      )}
      <ArrowSvg />
    </div>
  );
};

export default SelectMessenger;
