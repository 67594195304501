import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import TelegramSvg from "../../elements/TelegramSvg/TelegramSvg";
import { useNavigate } from "react-router-dom";
import BurgerSvg from "../../elements/BurgerSvg/BurgerSvg";
import BurgerOpenSvg from "../../elements/BurgerOpenSvg/BurgerOpenSvg";
import EnvelopeSvg from "../../elements/EnvelopeSvg/EnvelopeSvg";
import { ToastContainer, toast } from "react-toastify";

const Navbar = () => {
  const navigate = useNavigate();
  const howitwork = useRef();
  const blog = useRef();
  const otzivi = useRef();
  const burger_open = useRef();
  const burger = useRef();
  const nav_mobile = useRef();
  const menu = useRef();
  const [copy, setCopy] = useState(false);

  window.scrollTo(0, 0);

  useEffect(() => {
    if (window.location.pathname === "/how-it-works") {
      howitwork.current.classList.add("current");
    } else {
      howitwork.current.classList.remove("current");
    }
    if (
      window.location.pathname === "/blog" ||
      window.location.pathname === "/blog-item-one"
    ) {
      blog.current.classList.add("current");
    } else {
      blog.current.classList.remove("current");
    }
    if (window.location.pathname === "/otzivi") {
      otzivi.current.classList.add("current");
    } else {
      otzivi.current.classList.remove("current");
    }
  }, []);

  return (
    <div className="nav">
      <div ref={nav_mobile} className="nav_mobile">
          <img
            onClick={() => {
              navigate("/");
            }}
            src="/assets/logo.svg"
          />
          <div
            ref={burger}
            onClick={() => {
              burger.current.style.display = "none";
              menu.current.classList.toggle('openBurger')
              // nav_mobile.current.style.marginTop = "210px";
            }}
            className="nav_mobile_burger_close"
          >
            <BurgerSvg />
          </div>
          {/* <div
            onClick={() => {
              {
                burger_open.current.style.display = "none";
                burger.current.style.display = "flex";
                // menu.current.style.display = "none";
                // nav_mobile.current.style.marginTop = "0px";
              }
            }}
            ref={burger_open}
            className="nav_mobile_burger_open"
          ></div> */}
       
      </div>

      <ul>
        <li>
          <div onClick={() => navigate("/")}>
            {" "}
            <div className="box_logo">
              <img
                onClick={() => {
                  navigate("/");
                }}
                src="/assets/logo.svg"
              />
            </div>
          </div>
        </li>

        <li
          className="li-1"
          ref={howitwork}
          onClick={() => navigate("/how-it-works")}
        >
          Как это работает?
        </li>
        <li className="li-1" ref={blog} onClick={() => navigate("/blog")}>
          Блог
        </li>
        <li className="li-1" ref={otzivi} onClick={() => navigate("/otzivi")}>
          Отзывы
        </li>
        <li className="li-1">
          <a href="https://t.me/TransferDeliverySupport">Поддержка</a>
        </li>
        <form action="https://t.me/TransferDelivery_bot">
          <button className="btn ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="#007AFF"
            >
              <path
                d="M14.7011 1.08151L0.525852 7.60944C-0.0445851 7.91344 -0.237523 8.52221 0.387977 8.85261L4.02454 10.2327L12.8173 3.74327C13.2974 3.33587 13.7889 3.44451 13.3659 3.89267L5.81416 12.0582L5.57694 15.5139C5.79666 16.0475 6.19897 16.0499 6.4556 15.7847L8.54491 13.4239L12.1232 16.6237C12.9543 17.2113 13.4065 16.8321 13.5853 15.7552L15.9323 2.48332C16.176 1.15769 15.7605 0.573607 14.7011 1.08151Z"
                fill="#007AFF"
              ></path>
            </svg>
            Отправить перевод
          </button>
        </form>
      </ul>
      <div ref={menu} className="nav_mobile_burger_open_block">
          <div 
          onClick={()=>{
            burger.current.style.display = "block";
            menu.current.classList.toggle('openBurger');
          }}
          className="nav_mobile_burger_open_block_burger">
            <BurgerOpenSvg />
          </div>
          <div className="nav_mobile_burger_open_block_one">
            <span
              onClick={() => {
                navigate("/how-it-works");
              }}
            >
              Как это работает?
            </span>
            <span
              onClick={() => {
                navigate("/blog");
              }}
            >
              Блог
            </span>
            <span
              onClick={() => {
                navigate("/otzivi");
              }}
            >
              Отзывы
            </span>
            <span
              onClick={() => {
                navigate("/contacts");
              }}
            >
              Контакты
            </span>
          </div>
          <div className="nav_mobile_burger_open_block_footer">
            <div className="nav_mobile_burger_open_block_items">
              {copy ? (
                <div className="info">info.transferdelivery@gmail.com</div>
              ) : (
                <div
                  className="nav_mobile_burger_open_block_items_svg"
                  onClick={() => {
                    let inp = document.createElement("input");
                    inp.value = "info.transferdelivery@gmail.com";
                    document.body.appendChild(inp);
                    inp.select();
                    document.execCommand("copy");
                    document.body.removeChild(inp);
                    setCopy(true);
                    toast.info("Почта скопирована", {
                      position: toast.POSITION.BOTTOM_RIGHT,
                    });
                  }}
                >
                  <EnvelopeSvg />
                </div>
              )}

              <a
                className="nav_mobile_burger_open_block_items_svg"
                href="https://t.me/TransferDelivery_bot"
              >
                <TelegramSvg />
              </a>
            </div>
            <div className="nav_mobile_burger_open_block_footer_text">
              ©2024 TransferDelivery.net
            </div>
          </div>
        </div>
    </div>
  );
};

export default Navbar;
