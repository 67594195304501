import React from "react";

const EnvelopeSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.2478 10.7877L11.5023 12.9807L14.6395 10.8478L21.6717 17.6852C21.4866 17.7441 21.2888 17.7759 21.083 17.7759H1.91705C1.66405 17.7759 1.42255 17.7271 1.2006 17.6399L8.2478 10.7877ZM23 5.16868V15.8866C23 16.1665 22.9379 16.4317 22.8275 16.6709L15.9344 9.96835L23 5.16868ZM1.08514e-06 5.22875L6.9483 9.91168L0.121901 16.5507C0.0410512 16.3384 -0.000244068 16.1134 1.08514e-06 15.8866V5.22875ZM21.083 0.775879C22.1409 0.775879 23 1.62135 23 2.66515V3.32928L11.4977 11.1436L1.08514e-06 3.39388V2.66515C1.08514e-06 1.62248 0.857901 0.775879 1.91705 0.775879H21.083Z"
        fill="white"
      />
    </svg>
  );
};

export default EnvelopeSvg;
