import React from 'react';
import "./index.css";

import Layout from '../../layout/Layout';
import MainPageModuleSeven from '../../modules/MainPageModuleSeven/MainPageModuleSeven';

const Otzivi = () => {
    
    return (
       <Layout>
        <div className='otzivi'>
        <MainPageModuleSeven/>
        </div>
       </Layout>
    );
};

export default Otzivi;