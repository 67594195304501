import { $host } from "."


export const getCountries = async ()=> {
    const {data} = await $host.get('/countries')
    return data
}

export const getCurrency = async (currency)=> {
    const {data} = await $host.get(`/tariff_currency?currency=${currency}`)
    return data
}

export const getTariff = async ()=> {
    const {data} = await $host.get('/tariff_currency?currency=USD')
    return data
}

export const notification = async (user)=> {
    const {data} = await $host.post('/website/notification',{username: user})
    return data
}
export const getCountriesProperties = async (country)=> {
    const {data} = await $host.get(`/country?title=${country}`)
    return data
}

export const createTransaction = async (user_favorite_messenger,
    country_send,country_reception,pay_summa,currency_send,currency_reception,method,nick) => {
    const { data } = await $host.post("/transaction/create", { 
        user_id: null,
        user_favorite_messenger: user_favorite_messenger,
        country_send: country_send,
        country_reception:country_reception,
        pay_summa_send:pay_summa,
        currency_send: currency_send,
        currency_reception: currency_reception,
        method_transaction:method,
        name:'',
        nickname:nick,
        phone_contact:user_favorite_messenger === 'WhatsApp' ? nick: '',
    })
    return data
}