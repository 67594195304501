import React from "react";
import "./index.css";
import MainPageModuleOne from "../../modules/MainPageModuleOne/MainPageModuleOne";
import PossibilitiesModule from "../../modules/MainPagePossibilities/index";
import Layout from "../../layout/Layout";
import MainPageModuleThree from "../../modules/MainPageModuleThree/MainPageModuleThree";
import MainPageModuleCards from "../../modules/MainPageModuleCards/index";
import MainPageModuleSix from "../../modules/MainPageModuleSix/MainPageModuleSix";
import MainPageModuleSeven from "../../modules/MainPageModuleSeven/MainPageModuleSeven";
import MainPageModuleEight from "../../modules/MainPageModuleEight/MainPageModuleEight";
import MainPageModuleNine from "../../modules/MainPageModuleNine/MainPageModuleNine";

const MainPage = () => {
  return (
    <Layout>
      <div>
        <div className="block_one">
          <MainPageModuleOne />
          <PossibilitiesModule />
        </div>
        <div className="block_two">
          <MainPageModuleThree />
          <MainPageModuleCards />
          <MainPageModuleSix />

          <MainPageModuleSeven />
          <MainPageModuleEight />

          <MainPageModuleNine />
        </div>
      </div>

    </Layout>


  );
};

export default MainPage;
