import React from "react";
import Layout from "../../layout/Layout";
import "./index.css";


const EULA = () => {

  return (
    <Layout>
      <div className="eula_main">
        <div className="eula_container">
          <h1 className="title">Пользовательское соглашение сервиса TransferDelivery</h1>
          <span >December 18, 2023</span>
          <br />
          <br />
          <p className="text">
            Настоящие условия использования регулируют порядок использования
            Вами услуг, предоставляемых сервисом Transferdelivery.net (далее в
            тексте будут именоваться как «TransferDelivery»).
          </p>
          <p className="text">
            TransferDelivery предоставляет услуги по параллельному обмену
            фиатной валюты на криптовалюту и криптовалюты на фиатную валюту за
            пользователя (в дальнейшем именуемая «Услуга»)
          </p>
          <p className="text">
            Услуга доступна исключительно посредством Телеграм-бота
            TransferDelivery_bot (в дальнейшем именуемой «Платформа»). Платформа
            представляет собой частично автоматизированную онлайн-систему.
            Взаимодействуя с Платформой и пользуясь Услугами, Вы соглашаетесь с
            тем, что прочитали, поняли и приняли все условия использования
            TransferDelivery.
          </p>
          <p className="text">
            TransferDelivery оставляет за собой право в любой момент изменить
            условия использования в одностороннем порядке. Подобные изменения и
            поправки вступают в силу после их публикации на Сайте, но не
            распространяются на открытые в текущий момент сделки. Взаимодействие
            с Платформой и использование Услуг означает Ваше согласие с
            условиями использования, опубликованными на Сайте, на момент входа
            на Платформу. Если вы не согласны с условиями использования
            TransferDelivery, опубликованными на Сайте, то Вы должны немедленно
            прекратить работу с Платформой и использование Услуг. Продолжение
            использования Услуг TransferDelivery после внесения любых изменений
            в настоящие Условия означает Ваше согласие с измененными Условиями.
          </p>
          <h3 className="title">Термины и определения</h3>
          <p className="text">
            «Сервис» - программно-аппаратный комплекс, включающий в себя Сайт и
            Платформу, позволяющее осуществлять взаимодействие Пользователей с
            Сервисом.
          </p>
          <p className="text">«Пользователь» – лицо, пользующееся услугами Сервиса</p>
          <p className="text">
            «Сайт» - программно-аппаратный комплекс в сети Интернет по адресу:
            https://TransferDelivery.net.
          </p>
          <p className="text">
            «Вознаграждение» - сумма денежных средств, уплачиваемая
            Пользователем Сервису за услуги, оказываемые по Договору.
          </p>
          <p className="text">
            «Агентский договор» – заключенная между Пользователем и Исполнителем
            путем акцепта Пользователем настоящей оферты сделка агентирования,
            согласно которой:
          </p>
          <p className="text">
            - Исполнитель обязуется, действуя от своего имени, но за счет
            Пользователя, принять от Пользователя денежные средства и передать
            или перечислить их указанному Пользователем лицу (или самому
            Пользователю) в указанных Пользователем на Сайте стране и валюте;
          </p>
          <p className="text">
            - Пользователь обязуется уплатить Исполнителю Вознаграждение
            согласно Тарифам.
          </p>
          <p className="text">
            «Поручение» - выданное Пользователем в рамках Агентского договора
            поручение на обеспечение передачи или перечисления денежных средств.
          </p>
          <p className="text">
            «Тарифы» – размещенный на Сайте документ, содержащий сведения о
            размере Вознаграждения, сроках и порядке его уплаты.
          </p>
          <h3 className="title">1. Предмет договора</h3>
          <p className="text">
            1.1. В соответствии с Агентским договором Исполнитель обязуется,
            действуя от своего имени и за счет Пользователя, принять от
            Пользователя денежные средства, обеспечить передачу или перечисление
            указанному Пользователем лицу (или самому Пользователю) эквивалента
            суммы этих денежных средств в указанных Пользователем на Сайте
            стране и валюте, а Пользователь обязуется уплатить Исполнителю
            Вознаграждение согласно Тарифам.
          </p>
          <p className="text">
            Отчет об исполнении Агентского договора размещается в виде
            информации о совершенны действиях Пользователя на Платформе.
          </p>
          <p className="text">1.2. Пользователь указывает на Платформе:</p>
          <p className="text">
            - сумму денежных средств, которую Пользователь вносит (переводит)
            для исполнения Агентского договора, а также реквизиты счета, на
            который эквивалент этой суммы должен быть зачислен Исполнителем,
            либо адрес, где эквивалент этой суммы должен быть выдан наличными,
          </p>
          <p className="text">
            - валюта, в которой эквивалент этой суммы должен быть зачислен на
            счет или выдан наличными.
          </p>
          <p className="text">
            1.3. История сделанных с помощью Сервиса операций Пользователя
            доступна на Платформе в Истории переводов.
          </p>
          <p className="text">
            1.4. Сервис предоставляется «как есть»: Пользователь не вправе
            предъявлять претензии по отсутствию каких-либо функций или услуг в
            Сервисе.
          </p>
          <p className="text">
            1.5. Территория предоставления прав пользования Сервисом – все
            страны мира. Срок предоставления прав пользования Сервисом ограничен
            сроком действия настоящей оферты.
          </p>
          <p className="text">
            1.6. Агент вправе в одностороннем порядке вносить изменения в
            условия Агентского договора (в текст настоящей оферты).
          </p>
          <p className="text">
            1.6. Агент вправе в одностороннем порядке вносить изменения в
            условия Агентского договора (в текст настоящей оферты). Пользователь
            обязуется самостоятельно следить за изменениями редакции настоящей
            оферты путем периодического ознакомления с актуальной редакцией
            настоящей оферты не менее одного раза в два дня. Пользователь
            самостоятельно отвечает за любые последствия, наступившие в связи с
            не ознакомлением с актуальным текстом настоящей оферты.
          </p>
          <p className="text">
            В случае если Пользователь не согласен с каким-либо положением
            настоящей оферты, он обязан незамедлительно прекратить использование
            Сервиса.
          </p>
          <p className="text">
            1.7. Нарушения, которые могут повлечь за собой строгое
            предупреждение, блокирование Пользователю доступа к Сайту и/или
            Сервису:
          </p>
          <p className="text">
            - Пользователь не выполнил условия Агентского договора, заключенного
            с использованием Сервиса;
          </p>
          <p className="text">
            - Пользователь использовал ненормативную лексику, оскорбления,
            обсуждал вопросы, не связанные с сутью использования Сервиса;
          </p>
          <p className="text">
            - Получена жалоба на Пользователя от другого Пользователя (грубость,
            хамство, неадекватное общение, нарушение договоренностей и т. д.);
          </p>
          <p className="text">- Пользователь нарушил правила общения в чате и/или на Сайте;</p>
          <p className="text">
            -Пользователь нарушил правила общения со службой поддержки Сервиса.
            Служба поддержки призвана следить за соблюдением Агентского договора
            всеми Пользователями Сайта, а также давать рекомендации
            Пользователям. Для обсуждения спорных вопросов со службой поддержки
            Пользователям необходимо использовать только прямые контакты: личный
            чат, e-mail.
          </p>
          <h3 className="title">2. Порядок оказания услуги</h3>
          <p className="text">
            2.1. Срок, в течение которого внесенные Пользователем денежные
            средства перечисляются по указанным им реквизитам составляют не
            более 1 дня. В некоторых случаях этот срок может быть увеличен
            Исполнителем, о чем он уведомляет Пользователя в личных сообщениях в
            Телеграм. Если выдаются наличные, то пользователь вносит средства
            перед фактическим получением наличных.
          </p>
          <p className="text">
            2.2. В случае невозможности перечисления денежных средств (их части)
            по указанным Пользователем реквизитам или их выдачи наличными
            указанному Пользователем лицу, денежные средства (их часть)
            возвращаются Пользователю в той валюте, в которой они были от него
            получены.
          </p>
          <p className="text">
            2.3. Сумма денежных средств, переданных Пользователем Сервису,
            отражается на платформе при оформлении перевода.
          </p>
          <h3 className="title">3. Права и обязанности сторон договора</h3>
          <p className="text">3.1. Агент обязуется:</p>
          <p className="text">
            3.1.1. В разумный срок устранять сбои в работе Сервиса, возникшие по
            его вине.
          </p>
          <p className="text">
            3.1.2. Осуществлять техническую поддержку Пользователя по вопросам
            работы с Сервисом. Техническая поддержка и взаимодействие с Сервисом
            осуществляется в Рабочие дни, в Рабочее время по контактам,
            указанным на Сайте.
          </p>
          <p className="text">
            3.1.3. Сохранять конфиденциальность персональных данных
            Пользователя.
          </p>
          <p className="text">
            3.1.4. Обеспечивать за счет Пользователей исполнение Поручений
            Пользователя.
          </p>
          <p className="text">
            3.1.5. Информировать Пользователей об операциях через Платформу
            и/или сообщениями в социальной сети Телеграм.
          </p>
          <p className="text">
            3.1.6. Вернуть полученные от Пользователя денежные средства
            Пользователю в случае невозможности выполнения Поручения
            Пользователя, за вычетом понесенных Сервисом расходов на исполнение
            Поручения.
          </p>
          <p className="text">3.2. Пользователь обязан:</p>
          <p className="text">3.2.1. Использовать Cервис в соответствии с настоящей офертой.</p>
          <p className="text">
            3.2.2. Своевременно и в полном объеме ознакомиться с условиями
            настоящей оферты, а также с изменениями и дополнениями оферты,
            вносимыми Агентом.
          </p>
          <p className="text">3.2.3. Исполнять условия Агентского договора.</p>
          <p className="text">
            3.2.4. Своевременно актуализировать сведения, необходимые Агенту для
            для исполнения Поручений.
          </p>
          <p className="text">
            3.2.5. Гарантировать, что Поручения выдаются добровольно, без
            противоправных целей.
          </p>
          <p className="text">3.2.6. Своевременно уплачивать Вознаграждение.</p>
          <p className="text">
            3.2.7. В случае если любые предоставленные Пользователем Сервису
            данные изменились, Пользователь обязан в кратчайший срок с момента
            изменения этих данных сообщить об этом официальной службе поддержки.
          </p>
          <p className="text">
            3.2.8. В дату изменения каких-либо сведений о Пользователе, которые
            он указывал ранее в Сервисе, вносить соответствующие изменения и
            актуализировать эти сведения.
          </p>
          <p className="text">3.2.9. Осуществлять все действия в Сервисе только лично.</p>
          <p className="text">
            3.2.10. Знакомиться с изменениями настоящей оферты, размещаемой на
            Сайте.
          </p>
          <p className="text">
            3.2.11. Незамедлительно предоставлять запрошенные Сервисом документы
            и информацию.
          </p>
          <p className="text">
            3.2.12. Знакомиться с информацией, направляемой Сервисом
            Пользователю в смс-сообщениях, в мессенджерах, размещаемой на Сайте.
          </p>
          <p className="text">
            3.2.13. Признавать выданные Сервисом документы надлежащими
            доказательствами, подтверждающими как совершение юридических и
            фактических действий с использованием Сервиса, так и содержание этих
            действий.
          </p>
          <p className="text">
            3.2.14. Передавать Сервису только достоверную информацию и указывать
            письменно, если имеются сомнения в достоверности переданной Сервису
            информации.
          </p>
          <p className="text">3.3. Агент имеет право:</p>
          <p className="text">
            3.3.1. В случае нарушения Пользователем Агентского договора и/или
            предоставления недостоверной информации /документов и/или нарушения
            нормативных актов, либо нарушения этики или правил поведения в
            одностороннем внесудебном порядке расторгнуть Агентский договор,
            ограничить Пользователю часть функциональных возможностей. Агент не
            несет ответственности за убытки (ущерб), возникшие у Пользователей в
            связи такими действиями Сервиса.
          </p>
          <p className="text">
            Агент не обязан сообщать Пользователю причины расторжения Агентского
            договора. При расторжении этого договора, Пользователь должен
            оплатить Вознаграждение (если у него есть задолженность по уплате
            Вознаграждения) в течение 3 (Трех) рабочих дней после получения
            уведомления о расторжении от Сервиса и/или уведомления о
            необходимости уплатить задолженность по уплате Вознаграждения.
          </p>
          <p className="text">
            3.3.2. Проводить профилактические работы на сервере, для чего
            временно приостанавливать доступ к Сервису, предварительно уведомляя
            об этом Пользователей на Сайте.
          </p>
          <p className="text">
            3.3.3. В любой момент изменять Агентский договор и текст настоящей
            оферты (полностью или в части) в одностороннем порядке, без
            предварительного согласования с Пользователями. Все изменения
            вступают в силу через 1 (Один) день с даты размещения текста
            измененной оферты на Сайте, если более длительный срок вступления
            изменений в силу не определен дополнительно при публикации новой
            редакции оферты.
          </p>
          <p className="text">
            3.3.4. В любой момент удалить или ограничить доступ к любой
            размещенной на Сайте информации, нарушающей нормативные акты, права
            и законные интересы других лиц.
          </p>
          <p className="text">
            3.3.5. Требовать от Пользователей предоставления документов,
            необходимых для исполнения Поручений.
          </p>
          <p className="text">
            3.3.6. В любой момент времени отказать Пользователям в обеспечении
            исполнения Поручений, если имеется подозрение в том, что Поручения
            выданы с противоправными целями (с целью отмывания доходов,
            полученных преступным путем / финансирования терроризма / иной
            противоправной целью).
          </p>
          <p className="text">
            3.3.7. Передавать информацию о Пользователе и документы,
            предоставленные Пользователем, третьим лицам, если это необходимо
            для исполнения Поручений.
          </p>
          <p className="text">
            3.3.8. Не возвращать уплаченное Пользователем Вознаграждение, если
            исполнение Поручения невозможно по причинам, не зависящим от Агента.
          </p>
          <p className="text">
            3.3.9. В одностороннем порядке изменять Тарифы (размер и порядок
            уплаты Вознаграждения). Данные изменения вступают в силу на
            следующий день после их публикации на Сайте.
          </p>
          <p className="text">3.4. Пользователь имеет право:</p>
          <p className="text">
            3.4.1. На использование Сервиса в соответствии с Агентским
            договором.
          </p>
          <p className="text">3.4.2. Размещать Поручения в Сервисе.</p>
          <p className="text">
            3.4.3. Использовать функционал Сервиса только для выдачи Поручений,
            выдача которых предусмотрена данным Сервисом.
          </p>
          <p className="text">
            3.4.4. Использовать программное обеспечение, предоставленное
            Сервисом.
          </p>
          <p className="text">
            3.4.5. Обмениваться информацией с Сервисом в чате со службой
            поддержки в Телеграм и электронную почту.
          </p>
          <p className="text">3.5. Пользователь не вправе:</p>
          <p className="text">
            3.5.1. Предоставлять не соответствующие действительности сведения.
          </p>
          <p className="text">
            3.5.2. Использовать Сервис для легализации доходов, полученных
            преступным путем, обмана других Пользователем или Сервиса,
            заключения и/или исполнения мнимых и притворных сделок, а также для
            любых других операций в нарушение законодательства или Агентского
            договора.
          </p>
          <p className="text">3.5.3. Нарушать нормальную работу Сайта.</p>
          <p className="text">
            3.5.4. Осуществлять модификацию программного обеспечения, входящего
            в Сервис, а также иного программного обеспечения, размещенного на
            Сайте, в том числе изменять, декомпилировать, дешифровывать и
            производить иные действия с объектным кодом и исходным текстом.
          </p>
          <p className="text">
            3.5.5.Использовать Сервис и/или иное размещенное на Сайте
            программное обеспечение способом, не предусмотренным Агентским
            договором.
          </p>
          <p className="text">
            3.5.6. Распространять, копировать, обнародовать программное
            обеспечение, входящее в Сервис и/или иное программное обеспечение,
            размещенное на Сайте.
          </p>
          <p className="text">
            3.5.7. Использовать Сервис и/или иное размещенное на Сайте
            программное обеспечение и/или их части за пределами срока действия
            Агентского договора.
          </p>
          <p className="text">
            3.5.8. Создавать новое программное обеспечение и/или иные результаты
            интеллектуальной деятельности и/или сервисы с использованием Сервиса
            и/или иного размещенного на Сайте программного обеспечения.
          </p>
          <p className="text">
            3.5.9. Указывать данные третьих лиц в Сервисе без их
            предварительного письменного согласия.
          </p>
          <p className="text">
            3.5.10. Размещать в Сервисе материалы, содержащие: нецензурные,
            бранные слова или словосочетания, рекламу, порнографические
            изображения и тексты или сцены сексуального характера; угрозы,
            призывы к насилию и совершению противоправных действий, нарушению
            законодательства; элементы насилия, жестокости, расовой,
            межнациональной или межрелигиозной розни, а также ссылки на такие
            материалы; нацистскую атрибутику или символику; пропаганду
            преступной деятельности, руководства по совершению противоправных
            действий; результаты интеллектуальной деятельности третьих лиц (без
            согласия указанных лиц), информацию, не имеющую отношения к
            выдаваемым в Сервисе Поручениям или к их исполнению.
          </p>
          <p className="text">
            3.5.11. Осуществлять сбор, обработку, передачу, хранение или иное
            использование персональных данных других Пользователей и/или третьих
            лиц без их письменного согласия.
          </p>
          <p className="text">
            3.5.12. Осуществлять действия в Сервисе с использованием
            программного обеспечения, имитирующего действия Пользователя, а
            также осуществлять сбор и/или обработку размещенной в Сервисе
            информации.
          </p>
          <h3 className="title">4. Ответственность сторон договора</h3>
          <p className="text">
            4.1. Стороны Агентского договора несут ответственность за его
            исполнение в соответствии с применимым к Агентскому договору правом.
          </p>
          <p className="text">
            4.2. Агент не несет ответственность за любые прямые или косвенные
            убытки Пользователя, если они обусловлены поведением Пользователя
            или обстоятельствами, не зависящими напрямую от Агента (например, в
            случае ошибки в указанных Пользователем реквизитах для перечисления
            денежных средств).
          </p>
          <h3 className="title">5. Разрешение споров</h3>
          <p className="text">
            5.1. Все споры и разногласия, которые могут возникнуть в связи с
            исполнением Агентского договора, будут решаться путем переговоров.
          </p>
          <p className="text">
            5.2. В случае недостижения соглашения путем переговоров споры и
            разногласия подлежат разрешению в судебном порядке в суде по месту
            нахождения Сервиса. При любом судебном споре между сторонами
            Агентского договора применимым правом будет являться право страны
            местонахождения Сервиса.
          </p>
          <h3 className="title">6. Форс-мажор</h3>
          <p className="text">
            6.1. Сторона Агентского договора освобождается от ответственности за
            частичное или полное неисполнение обязательств по Агентскому
            договору, если докажет, что надлежащее исполнение оказалось
            невозможным вследствие непреодолимой силы, то есть чрезвычайных и
            непредотвратимых при настоящих условиях обстоятельств.
          </p>
          <p className="text">
            6.2. При наступлении обстоятельств, указанных в п. 6.1 Агентского
            договора, каждая сторона Агентского договора должна без промедления
            известить о них в письменном виде другую сторону Агентского
            договора.
          </p>
          <p className="text">
            6.3. В случаях наступления обстоятельств, предусмотренных в п. 6.1
            Агентского договора, срок выполнения стороной Агентского договора
            обязательств по этому договору отодвигается соразмерно времени, в
            течение которого действуют эти обстоятельства и их последствия.
          </p>
          <p className="text">
            6.4. Если наступившие обстоятельства, перечисленные в п. 6.1
            Агентского договора, и их последствия продолжают действовать более 1
            месяца, стороны Агентского договора проводят дополнительные
            переговоры для выявления приемлемых альтернативных способов
            исполнения Агентского договора.
          </p>
          <h3 className="title">7. Заключительные положения</h3>
          <p className="text">
            7.1. Все уведомления и сообщения друг другу стороны Агентского
            договора направляют в мессенджере Телеграм или e-mail.
          </p>
          <p className="text">
            7.2. Срок для акцепта настоящей Оферты Пользователями не
            ограничивается.Агентский договор на условиях настоящей оферты
            вступает в силу, а акцепт оферты считается совершенным, в момент
            нажатия Пользователем кнопки «Согласен», расположенной на Сайте под
            текстом настоящей оферты.
          </p>
          <p className="text">
            7.3. Все изменения и дополнения к Агентского договору действительны,
            если совершены в письменной форме и подписаны уполномоченными
            представителями сторон этого договора.
          </p>
          <p className="text">
            7.4. Агентский договор может быть досрочно расторгнут по письменному
            соглашению сторон договора.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default EULA;
