import React, { useRef } from "react";
import "./index.css";
import CheckArrowSvg from "../../elements/CheckArrowSvg/CheckArrowSvg";

const MainPageModuleSix = () => {
  const ref1 = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  const ref4 = useRef()
  const circle1 = useRef()
  const circle2 = useRef()
  const circle3 = useRef()
  const circle4 = useRef()
  return (
    <div className="module_six_container mb_container">
      <h1 className="title">TransferDelivery - это</h1>
      <div className="module_six_block">
        <div 
        onClick={()=>{
          ref1.current.classList.toggle('open')
          circle1.current.classList.toggle('open')

        }}
        ref={ref1}
        className="module_six_block_item">
          <div className="title module_six_block_item_title">
            <span className="title">Скорость</span>
            <div 
              ref={circle1}

            className="circle">
              <div 
              className="triangle"></div>
            </div>
          </div>
          <p className="text">
            Благодаря{'\u00A0'}широкой{'\u00A0'}инфрастуктуре компании, переводы{'\u00A0'}через
            TransferDelivery обрабатываются мгновенно или в течение нескольких
            минут.
          </p>
        </div>
        <div 
        ref={ref2}
        onClick={()=>{
          ref2.current.classList.toggle('open')
          circle2.current.classList.toggle('open')

        }}
        className="module_six_block_item">
          <div className="title module_six_block_item_title">
            
            <span className="title">Надёжность</span>
            <div
              ref={circle2}

             className="circle">
              <div 
        

              className="triangle"></div>
            </div>
          </div>
          <p className="text">
            Никаких заморозок, разворотов и потерянных платежей, с{'\u00A0'}чем{'\u00A0'}Вы{'\u00A0'}часто
            столкнётесь, отправляя переводы из банков или систем денежных
            переводов.
          </p>
        </div>

        <div 
        ref={ref3}
        onClick={()=>{
          ref3.current.classList.toggle('open')
          circle3.current.classList.toggle('open')

        }}
        
        className="module_six_block_item">
          <div className="title module_six_block_item_title">
            
            <span className="title">Безопасность</span>
            <div 
              ref={circle3}
            
            className="circle">
              <div 
           

              className="triangle"></div>
            </div>
          </div>
          <p className="text">
            Мы делаем всё, что в наших силах, чтобы обеспечить полную
            безопасность каждого перевода. Мы не банк, не обменник и не
            электронный кошелёк, и мы никогда не держим никаких средств. Вы
            оплачиваете перевод перед фактическим получением средств в офисе у
            наших партнёров.
          </p>
        </div>
        <div 
        ref={ref4}
        onClick={()=>{
          ref4.current.classList.toggle('open')
          circle4.current.classList.toggle('open')

        }}
        className="module_six_block_item">
          <div className="title module_six_block_item_title">
            
            <span className="title">Конфиденциальность</span>
            <div 
              ref={circle4}
            
            className="circle">
              <div 
       

              className="triangle"></div>
            </div>
          </div>
          <p className="text">
            Вы можете быстро и удобно осуществлять денежные переводы за границу
            в несколько кликов, без необходимости скачивания и регистрации. Вам
            останется лишь воспользоваться нашим телеграм ботом для оформления
            перевода.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MainPageModuleSix;
