import React from "react";
import Layout from "../../layout/Layout";
import "./index.css";
import TelegramSvg from "../../elements/TelegramSvg/TelegramSvg";


const Contacts = () => {

  return (
    <Layout>
      <div className="mt_container">
        <h2 className="title mb_title title_mb_left">
          Официальные ссылки на наши ресурсы
        </h2>
        <div className="how_it_works_official_links">
          <div className="how_it_works_official_links_item">

            <div className="how_it_works_official_links_item_body">
              <h3 className="how_it_works_official_links_item_title title">
                Официальная служба поддержки TransferDelivery
              </h3>
              <div className="how_it_works_official_links_item_text  text">
                Занимается подтверждением переводов, обработками заявок, консультирует по&nbsp;работе сервиса <a href="https://transfer-dlivery.ru/">TransferDelivery</a>.
              </div>
              <div className="how_it_works_official_links_item_text text">
                Имя{'\u00A0'}пользователя: <a href="https://t.me/TransferDeliverySupport">@TransferDeliverySupport</a>
              </div>
              <form action="https://t.me/TransferDeliverySupport">
                <button className="btn"><TelegramSvg />Открыть профиль</button>
              </form>
            </div>
            <div className="how_it_works_official_links_item_img_wrapper official_link_1">

            </div>

          </div>
          <div className="how_it_works_official_links_item">
            <div className="how_it_works_official_links_item_body">
              <div className="how_it_works_official_links_item_title title">
                Группа с новостями TransferDelivery
              </div>
              <div className="how_it_works_official_links_item_text text">
                Здесь мы делимся актуальной информацией о работе сервиса <a href="https://transfer-dlivery.ru/">TransferDelivery</a>, новостями и обновлениями.
              </div>
              <div className="how_it_works_official_links_item_text text">
                Ссылка{'\u00A0'}на{'\u00A0'}канал: <a href="https://t.me/transferdelivery">@transferdelivery</a>
              </div>
              <form action="https://t.me/transferdelivery">
                <button className="btn"><TelegramSvg />Открыть профиль</button>
              </form>
            </div>
            <div className="how_it_works_official_links_item_img_wrapper official_link_2">

            </div>
          </div>
          <div className="how_it_works_official_links_item">
            <div className="how_it_works_official_links_item_body">
              <div className="how_it_works_official_links_item_title title">
                Бот для оформления перевода
              </div>
              <div className="how_it_works_official_links_item_text text">
                С помощью телеграм бота, Вы можете оформить перевод за границу
                в 2 клика без необходимости скачивания и регистрации.
              </div>
              <div className="how_it_works_official_links_item_text text">
                Ссылка{'\u00A0'}на{'\u00A0'}бот: <a href="https://t.me/TransferDelivery_bot">@TransferDelivery_bot</a>
              </div>
              <form action="https://t.me/TransferDelivery_bot">
                <button className="btn"><TelegramSvg />Открыть профиль</button>
              </form>
            </div>
            <div className="how_it_works_official_links_item_img_wrapper official_link_3">

            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contacts;
