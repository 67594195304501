import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import SelectArrowSvg from "../SelectArrowSvg/SelectArrowSvg";
import ArrowSvg from "../ArrowSvg/ArrowSvg";
import { getCountries } from "../../http/transactionAPI";
import { Countries, IsOpenCountry, IsOpenCurrency, IsOpenMethod, IsOpenSendCountry, IsOpenSendCurrency, SendCountries } from "../..";

const SelectSendCountry = ({redSendCountry}) => {
  const [sendCountry, setSendCountry] = useContext(SendCountries);
  const [title, setTitle] = useState("Выберите страну отправления");
  const [isOpenSendCountry, setIsOpenSendCountry] = useContext(IsOpenSendCountry);
  const [isOpenMethod, setIsOpenMethod] = useContext(IsOpenMethod);
const ref = useRef()
  const [isOpenSendCurrency, setIsOpenSendCurrency] = useContext(IsOpenSendCurrency);
  const [isOpenCountry, setIsOpenCountry] = useContext(IsOpenCountry);
  const [isOpenCurrency, setIsOpenCurrency] = useContext(IsOpenCurrency);
  const [array, setArray] = useState([]);
  const [array1, setArray1] = useState([]);
  const [array2, setArray2] = useState([]);
  const img_title = useRef();

  const [flag, setFlag] = useState();

  const [isLoad, setIsLoad] = useState(false);

  const countries = [];
  const country_name = [];

  useEffect(() => {
    setIsLoad(true);
    getCountries().then((response) => {
      response.countries.forEach((item) => {
        countries.push(item);
        setArray1(countries);
      });
      countries.forEach((item) => {
        country_name.push(item.title_rus);
        setArray(country_name);
      });
      setIsLoad(false);
    });
  }, []);
  useEffect(() => {
    if (isOpenCountry) setIsOpenSendCountry(false);

    if (isOpenSendCurrency) setIsOpenSendCountry(false);
    if (isOpenMethod) setIsOpenSendCountry(false);
    if (isOpenCurrency) setIsOpenSendCountry(false);
    if (isOpenSendCountry) setIsOpenCountry(false);
    if (isOpenSendCountry) setIsOpenMethod(false);
    if (isOpenSendCountry) setIsOpenCurrency(false);
    if (isOpenSendCountry) setIsOpenSendCurrency(false);
    if (redSendCountry) {
      ref.current.style.border = '3px solid red'
    } else {
      ref.current.style.border = 'none'

    }
    if (title !== "Выберите страну отправления")
   { ref.current.style.border = 'none'

      img_title.current.style.display = "block";}
  });
  return (
    <div
    ref={ref}
      onClick={
        isOpenSendCountry
          ? () => {
            setIsOpenSendCountry(false);
          }
          : () => {
            setIsOpenSendCountry(true);
          }
      }
      className="select_btn_container"
    >
      <div className="select_container__title">
        <img ref={img_title} src={`/assets/flags/${flag}.png`} />
        {title}
      </div>
      {isOpenSendCountry ? (
        <div className="select_options">
          {isLoad ? (
            <span className="spinner"></span>
          ) : (
            array.map((item) => {
              // if (item === title) {
              return (
                <div
                  className="select_option"
                  key={item}
                  onClick={() => {
                    setTitle(item);
                    array1.forEach((element) => {
                      if (item === element.title_rus) {
                        setSendCountry(element);
                        setFlag(element.title_rus);
                      }
                    });
                  }}
                >
                  {array1.map((element) => {
                    if (item === element.title_rus) {
                      return <img key={item} src={`/assets/flags/${element.title_rus}.png`} />;
                    }
                  })}

                  {/* <SelectArrowSvg /> */}
                  {item}
                </div>
              );
              // } else {
              //   return (
              //     <div
              //       className="select_option"
              //       key={item}
              //       onClick={() => {
              //         setTitle(item);
              //        array1.forEach((element) => {
              //           if (item === element.title_rus) {
              //             setCountry(element.title);
              //             setFlag(element.title)

              //           }
              //         });
              //       }}
              //     >
              //       <img src={`/assets/flags/${flag}.png`}/>
              //       {item}
              //     </div>
              //   );
              // }
            })
          )}
        </div>
      ) : (
        ""
      )}
      <ArrowSvg />
    </div>
  );
};

export default SelectSendCountry;
