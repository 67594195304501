import React from "react";
import "./index.css";
import TelegramSvg from "../../elements/TelegramSvg/TelegramSvg";
import TelegramColorSvg from "../../elements/TelegramColorSvg/TelegramColorSvg";

const MainPageModuleNine = () => {
  return (
    <div className="module_nine_container">
      <div className="module_nine_block">
        <div className="title mb_title">
          Подписывайтесь на телеграм канал сервиса
        </div>
        <div className="module_nine_description">
          <span className="text">
            Ещё больше информации о работе сервиса, новостях и обновлениях Вы найдёте в нашем телеграм канале.
          </span>
        </div>
        <div className="module_nine_footer">
          <form action="https://t.me/TransferDelivery_bot">
            <button className="btn">
              <TelegramSvg />
              <span>Отправить перевод</span>
            </button>
          </form>

          <form action="https://t.me/transferdelivery">
            <button className="btn module_nine_footer_btn_2">
              <TelegramSvg />
              <span>Телеграм канал сервиса</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MainPageModuleNine;
