import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import SelectArrowSvg from "../SelectArrowSvg/SelectArrowSvg";
import ArrowSvg from "../ArrowSvg/ArrowSvg";
import { getCountries, getCountriesProperties } from "../../http/transactionAPI";
import { Countries, Currencies, IsOpenCountry, IsOpenCurrency, IsOpenMethod, IsOpenSendCountry, IsOpenSendCurrency, SendCountries, SendCurrencies } from "../..";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const SelectSendCurrency = () => {
  const [sendCountry, setSendCountry] = useContext(SendCountries);
  const [currency, setCurrency] = useContext(Currencies);
  const [title, setTitle] = useState("USD");
  const [isOpenSendCountry, setIsOpenSendCountry] = useContext(IsOpenSendCountry);
  const [isOpenMethod, setIsOpenMethod] = useContext(IsOpenMethod);

  const [isOpenSendCurrency, setIsOpenSendCurrency] = useContext(IsOpenSendCurrency);
  const [isOpenCountry, setIsOpenCountry] = useContext(IsOpenCountry);
  const [isOpenCurrency, setIsOpenCurrency] = useContext(IsOpenCurrency);

  const [array, setArray] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const currencies = [];
  const currency_name = [];
  useEffect(() => {
    setIsLoad(true)
    if (sendCountry !== "Выберите страну отправления")
      getCountriesProperties(sendCountry.title).then((response) => {
        response.country.currencies.forEach((item) => {
          currencies.push(item);
        });
        currencies.forEach((item) => {
          currency_name.push(item.title);
          setArray(currency_name)
          setTitle('USD')
        });
        setIsLoad(false)
      });
  }, [sendCountry])

  useEffect(() => {
    // if (isOpenCountry) setIsOpenCurrency(false);
    // if (isOpenSendCurrency) setIsOpenCurrency(false);

    // if (isOpenSendCountry) setIsOpenCurrency(false);
    // if (isOpenMethod) setIsOpenCurrency(false);
    if (isOpenCurrency) setIsOpenSendCountry(false);
    if (isOpenCurrency) setIsOpenMethod(false);
    if (isOpenCurrency) setIsOpenSendCurrency(false);
    if (isOpenCurrency) setIsOpenCountry(false);
   
  })

  return (
    <div
      onClick={sendCountry !== 'Выберите страну отправления' ?
        isOpenCurrency ? () => { setIsOpenCurrency(false) } : () => { setIsOpenCurrency(true) }
        : () => toast.error("Выберите страну отправления", {
          position: toast.POSITION.BOTTOM_RIGHT,
        })}
      className="select_btn_container_send_currency">
      <div className="select_container__title">{title}</div>
      {isOpenCurrency ? (
        <div className="select_options_currency">
          {isLoad ? (
            <span className="spinner"></span>
          ) : (
            array.map((item) => {
              if (item === title) {
                return (
                  <div
                    className="select_optionArrow"
                    key={item}
                    onClick={() => { setTitle(item); setCurrency(item) }}
                  >
                    <SelectArrowSvg />
                    {item}
                  </div>
                );
              } else {
                return (
                  <div
                    className="select_option"
                    key={item}
                    onClick={() => { setTitle(item); setCurrency(item) }}
                  >
                    {item}
                  </div>
                );
              }
            })
          )}
        </div>
      ) : (
        ""
      )}
      <ArrowSvg />
    </div>
  );
};

export default SelectSendCurrency;
