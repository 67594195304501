import React from "react";
import "./index.css";

const PossibilitiesModule = () => {
  return (
    <div className="possibility_container mb_container">
      <div className="possibility_title_wrapper mb_title">
        <h2 className="title possibility_title">
          Возможности{'\u00A0'}<span className="text_blue">TransferDelivery</span>
        </h2>
        <p className="text possibility_title_text">
          Решаем любые задачи, связанные с&nbsp;международными переводами
        </p>
      </div>
      <div className="possibility_content_wrapper">
        <ul className="possibility_items">

          <li className="possibility_item">
            <div className="possibility_item_content_wrapper">
              <div className="possibility_item_content_image possibility_item_content_image_1"></div>
              <p className="text">
                Переводим валюту на&nbsp;зарубежную карту или счёт в&nbsp;течение 10&nbsp;минут
              </p>
            </div>
          </li>

          <li className="possibility_item">
            <div className="possibility_item_content_wrapper">
              <div className="possibility_item_content_image possibility_item_content_image_2"></div>
              <p className="text">
                Выдаём наличные доллары или местную валюту в&nbsp;более 35-ти странах
              </p>
            </div>
          </li>

          <li className="possibility_item">
            <div className="possibility_item_content_wrapper">
              <div className="possibility_item_content_image possibility_item_content_image_3"></div>
              <p className="text">
                Выдаём переводы в&nbsp;долларах, евро и&nbsp;других валютах
              </p>
            </div>
          </li>

          <li className="possibility_item">
            <div className="possibility_item_content_wrapper">
              <div className="possibility_item_content_image possibility_item_content_image_4"></div>
              <p className="text">
                Помогаем с&nbsp;оплатой недвижимости или автомобилей за&nbsp;рубежом
              </p>
            </div>
          </li>

        </ul>
      </div>
    </div>
  );
};

export default PossibilitiesModule;
