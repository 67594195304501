import React, { createContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

export const Countries = createContext()
export const Methods = createContext()
export const Messenger = createContext()


export const Currencies = createContext()
export const IsOpenCurrency = createContext()
export const IsOpenCountry = createContext()
export const SendCountries = createContext()
export const SendCurrencies = createContext()
export const IsOpenSendCurrency = createContext()
export const IsOpenSendCountry = createContext()
export const IsOpenMethod = createContext()
export const IsOpenMessenger = createContext()




const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
