import React from "react";

const OtziviArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="none"
    >
      <path
        d="M0 11.4813C0 5.19087 5.36471 0.10498 12 0.10498C18.6353 0.10498 24 5.19087 24 11.4813C24 17.7718 18.6353 22.8576 12 22.8576C5.36471 22.8576 0 17.7718 0 11.4813ZM22.5882 11.4813C22.5882 5.92699 17.8588 1.44337 12 1.44337C6.14118 1.44337 1.41176 5.92699 1.41176 11.4813C1.41176 17.0356 6.14118 21.5193 12 21.5193C17.8588 21.5193 22.5882 17.0356 22.5882 11.4813Z"
        fill="#007AFF"
      />
      <path
        d="M10.8 17.0358L16.6588 11.4814L10.8 5.92711L11.7882 4.99023L18.6353 11.4814L11.7882 17.9726L10.8 17.0358Z"
        fill="#007AFF"
      />
      <path
        d="M17.6471 10.8123V12.1506H5.64706V10.8123H17.6471Z"
        fill="#007AFF"
      />
    </svg>
  );
};

export default OtziviArrow;
