import React from "react";

const SelectArrowSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0,0,256,256"
    width="15px"
    height="15px"
  >
    <g
      fill="none"
      fillRule="nonzero"
      stroke="none"
      strokeWidth="none"
    >
      <path
        transform="scale(5.12,5.12)"
        d="M43.875,9.08594c0.21094,0.33984 0.19922,0.77344 -0.03125,1.10156l-21.09375,32.6875c-0.15625,0.24609 -0.41016,0.41016 -0.69922,0.45313c-0.28906,0.04297 -0.58203,-0.04297 -0.80078,-0.23437l-14.90625,-13.5c-0.33984,-0.21094 -0.51953,-0.60156 -0.46094,-0.99609c0.05859,-0.39453 0.34375,-0.71875 0.73047,-0.82031c0.38672,-0.10156 0.79688,0.03516 1.04297,0.34766l14.0625,12.6875l20.4375,-31.6875c0.13672,-0.23437 0.36328,-0.40234 0.625,-0.46875c0.03125,-0.01172 0.0625,-0.02344 0.09375,-0.03125c0.39453,-0.05859 0.78906,0.12109 1,0.46094z"
        id="strokeMainSVG"
        fill="#dddddd"
        stroke="#dddddd"
        strokeWidth="3"
        strokeLinejoin="round"
      />
      <g
        transform="scale(5.12,5.12)"
        fill="#cdcaca"
        stroke="none"
        strokeWidth="1"
        strokeLinejoin="miter"
      >
        <path d="M42.875,8.625c-0.03125,0.00781 -0.0625,0.01953 -0.09375,0.03125c-0.26172,0.06641 -0.48828,0.23438 -0.625,0.46875l-20.4375,31.6875l-14.0625,-12.6875c-0.24609,-0.3125 -0.65625,-0.44922 -1.04297,-0.34766c-0.38672,0.10156 -0.67187,0.42578 -0.73047,0.82031c-0.05859,0.39453 0.12109,0.78516 0.46094,0.99609l14.90625,13.5c0.21875,0.19141 0.51172,0.27734 0.80078,0.23438c0.28906,-0.04297 0.54297,-0.20703 0.69922,-0.45312l21.09375,-32.6875c0.23047,-0.32812 0.24219,-0.76172 0.03125,-1.10156c-0.21094,-0.33984 -0.60547,-0.51953 -1,-0.46094z" />
      </g>
    </g>
  </svg>
);

export default SelectArrowSvg;
