import './App.css';
import {Route,Routes } from 'react-router-dom';
import Layout from './layout/Layout';
import Blog from './pages/Blog/Blog';
import BlogItemPage from './pages/BlogItemPage/BlogItemPage';
import HowItWorks from './pages/HowItWorks/HowItWorks';
import MainPage from './pages/MainPage/MainPage';
import Otzivi from './pages/Otzivi/Otzivi';
import PrivacyPolice from './pages/PrivacyPolice/PrivacyPolice';
import EULA from './pages/EULA/EULA';
import Contacts from './pages/Contacts/Contacts';
import BlogItemPage2 from './pages/BlogItemPage/BlogItemPage2';
function App() {
  return (
    <Routes>
        <Route index element={<MainPage />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/blog" element={<Blog />} />
        <Route path='/blog-item-one' element={<BlogItemPage />} />
        <Route path='/blog-item-two' element={<BlogItemPage2 />} />
        <Route path='/otzivi' element={<Otzivi />} />
        <Route path='/privacy-police' element={<PrivacyPolice />} />
        <Route path='/eula' element={<EULA />} />
        <Route path='/contacts' element={<Contacts />} />

    </Routes>
  );
}

export default App;
