import React, { useEffect, useRef, useState } from 'react';
import SliderArrowSvg from '../../elements/SliderArrowSvg/SliderArrowSvg';


const Slider = () => {
    const slide_1 = useRef();
    const slide_2 = useRef();
    const slide_3 = useRef();
    const slide_4 = useRef();
    const slide_5 = useRef();
    const slide_6 = useRef();
    const slide_7 = useRef();
    const footer_slide_1 = useRef();
    const footer_slide_2 = useRef();
    const footer_slide_3 = useRef();
    const footer_slide_4 = useRef();
    const footer_slide_5 = useRef();
    const footer_slide_6 = useRef();
    const footer_slide_7 = useRef();

    const leftArrow = useRef();
    const rightArrow = useRef();
    const leftMainArrow = useRef();
    const rightMainArrow = useRef();
    const [slideIndex, setSlideIndex] = useState(1);

    useEffect(() => {
      if (slideIndex === 1) {
        leftArrow.current.style.opacity = "0.2";
        leftMainArrow.current.style.opacity = "0.2";
        slide_1.current.style.display = "flex";
        slide_2.current.style.display = "none";
        slide_3.current.style.display = "none";
        slide_4.current.style.display = "none";
        slide_5.current.style.display = "none";
        slide_6.current.style.display = "none";
        slide_7.current.style.display = "none";
        footer_slide_1.current.style.display = "flex";
        footer_slide_2.current.style.display = "flex";
        footer_slide_3.current.style.display = "flex";
        footer_slide_4.current.style.display = "flex";
        footer_slide_5.current.style.display = "none";
        footer_slide_6.current.style.display = "none";
        footer_slide_7.current.style.display = "none";
        footer_slide_1.current.style.border = "2px solid #007AFF";
        footer_slide_2.current.style.border = "none";
        footer_slide_3.current.style.border = "none";
        footer_slide_4.current.style.border = "none";
        footer_slide_5.current.style.border = "none";
        footer_slide_6.current.style.border = "none";
        footer_slide_7.current.style.border = "none";

      }
      if (slideIndex === 2) {
        slide_1.current.style.display = "none";
        slide_2.current.style.display = "flex";
        slide_3.current.style.display = "none";
        slide_4.current.style.display = "none";
        slide_5.current.style.display = "none";
        slide_6.current.style.display = "none";
        slide_7.current.style.display = "none";
        footer_slide_1.current.style.display = "flex";
        footer_slide_2.current.style.display = "flex";
        footer_slide_3.current.style.display = "flex";
        footer_slide_4.current.style.display = "flex";
        footer_slide_5.current.style.display = "none";
        footer_slide_6.current.style.display = "none";
        footer_slide_7.current.style.display = "none";
        footer_slide_1.current.style.border = "none";
        footer_slide_2.current.style.border = "2px solid #007AFF";
        footer_slide_3.current.style.border = "none";
        footer_slide_4.current.style.border = "none";
        footer_slide_5.current.style.border = "none";
        footer_slide_6.current.style.border = "none";
        footer_slide_7.current.style.border = "none";

      }
      if (slideIndex === 3) {
        slide_1.current.style.display = "none";
        slide_2.current.style.display = "none";
        slide_3.current.style.display = "flex";
        slide_4.current.style.display = "none";
        slide_5.current.style.display = "none";
        slide_6.current.style.display = "none";
        slide_7.current.style.display = "none";
        footer_slide_1.current.style.display = "flex";
        footer_slide_2.current.style.display = "flex";
        footer_slide_3.current.style.display = "flex";
        footer_slide_4.current.style.display = "flex";
        footer_slide_5.current.style.display = "none";
        footer_slide_6.current.style.display = "none";
        footer_slide_7.current.style.display = "none";
        footer_slide_1.current.style.border = "none";
        footer_slide_2.current.style.border = "none";
        footer_slide_3.current.style.border = "2px solid #007AFF";
        footer_slide_4.current.style.border = "none";
        footer_slide_5.current.style.border = "none";
        footer_slide_6.current.style.border = "none";
        footer_slide_7.current.style.border = "none";

      }
      if (slideIndex !== 1) {
        leftArrow.current.style.opacity = "1";
        leftMainArrow.current.style.opacity = "1";
      }
      if (slideIndex === 4) {
        slide_1.current.style.display = "none";
        slide_2.current.style.display = "none";
        slide_3.current.style.display = "none";
        slide_4.current.style.display = "flex";
        slide_5.current.style.display = "none";
        slide_6.current.style.display = "none";
        slide_7.current.style.display = "none";
        footer_slide_1.current.style.display = "flex";
        footer_slide_2.current.style.display = "flex";
        footer_slide_3.current.style.display = "flex";
        footer_slide_4.current.style.display = "flex";
        footer_slide_5.current.style.display = "none";
        footer_slide_6.current.style.display = "none";
        footer_slide_7.current.style.display = "none";
        footer_slide_1.current.style.border = "none";
        footer_slide_2.current.style.border = "none";
        footer_slide_3.current.style.border = "none";
        footer_slide_4.current.style.border = "2px solid #007AFF";
        footer_slide_5.current.style.border = "none";
        footer_slide_6.current.style.border = "none";
        footer_slide_7.current.style.border = "none";

      }
      if (slideIndex === 5) {
        slide_1.current.style.display = "none";
        slide_2.current.style.display = "none";
        slide_3.current.style.display = "none";
        slide_4.current.style.display = "none";
        slide_5.current.style.display = "flex";
        slide_6.current.style.display = "none";
        slide_7.current.style.display = "none";
        footer_slide_1.current.style.display = "none";
        footer_slide_2.current.style.display = "flex";
        footer_slide_3.current.style.display = "flex";
        footer_slide_4.current.style.display = "flex";
        footer_slide_5.current.style.display = "flex";
        footer_slide_6.current.style.display = "none";
        footer_slide_7.current.style.display = "none";
        footer_slide_1.current.style.border = "none";
        footer_slide_2.current.style.border = "none";
        footer_slide_3.current.style.border = "none";
        footer_slide_4.current.style.border = "none";
        footer_slide_5.current.style.border = "2px solid #007AFF";
        footer_slide_6.current.style.border = "none";
        footer_slide_7.current.style.border = "none";

      }

      if (slideIndex === 6) {
        slide_1.current.style.display = "none";
        slide_2.current.style.display = "none";
        slide_3.current.style.display = "none";
        slide_4.current.style.display = "none";
        slide_5.current.style.display = "none";
        slide_6.current.style.display = "flex";
        slide_7.current.style.display = "none";
        footer_slide_1.current.style.display = "none";
        footer_slide_2.current.style.display = "none";
        footer_slide_3.current.style.display = "flex";
        footer_slide_4.current.style.display = "flex";
        footer_slide_5.current.style.display = "flex";
        footer_slide_6.current.style.display = "flex";
        footer_slide_7.current.style.display = "none";
        footer_slide_1.current.style.border = "none";
        footer_slide_2.current.style.border = "none";
        footer_slide_3.current.style.border = "none";
        footer_slide_4.current.style.border = "none";
        footer_slide_5.current.style.border = "none";
        footer_slide_6.current.style.border = "2px solid #007AFF";
        footer_slide_7.current.style.border = "none";

      }
      if (slideIndex === 7) {
        rightArrow.current.style.opacity = "0.2";
        rightMainArrow.current.style.opacity = "0.2";
        slide_1.current.style.display = "none";
        slide_2.current.style.display = "none";
        slide_3.current.style.display = "none";
        slide_4.current.style.display = "none";
        slide_5.current.style.display = "none";
        slide_6.current.style.display = "none";
        slide_7.current.style.display = "flex";
        footer_slide_1.current.style.display = "none";
        footer_slide_2.current.style.display = "none";
        footer_slide_3.current.style.display = "none";
        footer_slide_4.current.style.display = "flex";
        footer_slide_5.current.style.display = "flex";
        footer_slide_6.current.style.display = "flex";
        footer_slide_7.current.style.display = "flex";
        footer_slide_1.current.style.border = "none";
        footer_slide_2.current.style.border = "none";
        footer_slide_3.current.style.border = "none";
        footer_slide_4.current.style.border = "none";
        footer_slide_5.current.style.border = "none";
        footer_slide_6.current.style.border = "none";
        footer_slide_7.current.style.border = "2px solid #007AFF";

      }
      if (slideIndex !== 7) {
        rightArrow.current.style.opacity = "1";
        rightMainArrow.current.style.opacity = "1";
      }
    });

    const leftArrowClick = () => {
      if (slideIndex > 1) {
        setSlideIndex(slideIndex - 1);
      } else {
        setSlideIndex(1);
      }
    };
    const rightArrowClick = () => {
      if (slideIndex < 7) {
        setSlideIndex(slideIndex + 1);
      } else {
        setSlideIndex(7);
      }
    };
    return (
        <div className="how_it_works_slider">
        <div className="slider_main">
          <div
            onClick={leftArrowClick}
            ref={leftMainArrow}
            className="slider_main_left_arrow"
          >
            <SliderArrowSvg />
          </div>
          <div  className="slider_main_body">
            <div ref={slide_1} className="slider_body_item">
              <div className="slider_body_item_text">
                <h2 className="title mb_title">Телеграм-бот и его возможности</h2>
                <p className="text">С помощью телеграм бота, Вы сможете:</p>
                <p className="text">
                  1. Оформить перевод за границу в 2 клика без{'\u00A0'}необходимости
                  скачивания и регистрации
                </p>
                <p className="text">2. Отследить историю переводов и статус перевода</p>
                <p className="text">
                Чтобы оформить перевод, необходимо перейти в&nbsp;телеграм-бота и&nbsp;нажать кнопку &laquo;Отправить перевод&raquo;
                </p>
              </div>
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide1.png" alt="img" />
              </div>

            </div>
            <div ref={slide_2} className="slider_body_item">
              <div className="slider_body_item_text">
                <h2 className="title mb_20">Создание перевода</h2>
                <h3 className="title mb_title">Шаг 1 - Заполните данные для перевода</h3>
                <p className="text">
                Выберите страну отправления, укажите сумму и&nbsp;валюту, которую хотите отправить, выберите страну получения, валюту в&nbsp;которой хотите получить перевод и&nbsp;способ получения.
                </p>
              </div>
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide2.png" alt="img" />
              </div>

            </div>
            <div ref={slide_3} className="slider_body_item">
              <div className="slider_body_item_text">
                <h3 className="title">Шаг 2 - Введите данные получателя</h3>
                <p className="text">
                Введите номер телефона, никнейм-телеграм, фамилию, имя и&nbsp;отчество получателя, как в&nbsp;паспорте.
                </p>
                <p className="text _italic">
                  * Перед выдачей перевода в&nbsp;офисе, получателю необходимо предъявить паспорт для сверки данных, во&nbsp;избежания того, чтобы перевод не&nbsp;выдали другому лицу.
                </p>
              </div>
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide3.png" alt="img" />
              </div>

            </div>
            <div ref={slide_4} className="slider_body_item">
              <div className="slider_body_item_text">
                <h3 className="title">
                Шаг 3&nbsp;&mdash; Проверьте правильность введённых данных и&nbsp;исправьте при необходимости
                </h3>
                <p className="text">
                Проверьте правильность введённых данных и&nbsp;нажмите на&nbsp;кнопку &quot;Далее&quot;.
                В&nbsp;течение нескольких минут с&nbsp;Вами свяжется служба поддержки TransferDelivery для уточнения условий перевода.
                </p>
              </div>
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide4.png" alt="img" />
              </div>

            </div>
            <div ref={slide_5} className="slider_body_item">
              <div className="slider_body_item_text">
                <h3 className="title">Шаг 4&nbsp;&mdash; Подтвердите перевод</h3>
                <p className="text">
                После подтверждения и&nbsp;согласования условий перевода, мы&nbsp;приступим к&nbsp;его выполнению. Вам придёт уведомление от&nbsp;бота о&nbsp;том, что статус перевода &laquo;В процессе&raquo;.
                </p>
                <h3 className="title">Шаг 5&nbsp;&mdash; Оплатите перевод</h3>
                <p className="text">
                Служба поддержки отправит Вам в&nbsp;чат реквизиты для оплаты перевода. В&nbsp;случае, если способ получения на&nbsp;карту или счёт, оплата происходит до&nbsp;получения перевода, если способ получения наличными, то&nbsp;оплата производится в&nbsp;офисе, перед фактическим получением перевода.
                </p>
                <h2 className="title">Шаг 6&nbsp;&mdash; Получите перевод</h2>
                <p className="text">
                Моментально получите перевод наличными или на&nbsp;зарубежную карту в&nbsp;течение 10&nbsp;минут. После успешного выполнения перевода, служба поддержки отправит чек о&nbsp;выполнении платежа.
                </p>
              </div>
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide5.png" alt="img" />
              </div>

            </div>
            <div ref={slide_6} className="slider_body_item">
              <div className="slider_body_item_text">
                <h2 className="title">
                Отслеживания статуса перевода
                </h2>
                <p className="text">
                Вы&nbsp;можете отслеживать статус каждого перевода в&nbsp;разделе &quot;Отследить перевод&quot;.
                </p>

              </div>
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide7.png" alt="img" />
              </div>

            </div>
            <div ref={slide_7} className="slider_body_item">
              <div className="slider_body_item_text">
                <h2 className="title">История операций</h2>
                <p className="text">
                История Ваших переводов всегда доступнав разделе &laquo;История переводов&raquo;.
                </p>
              </div>
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide8.png" alt="img" />
              </div>
            </div>

          </div>
          <div
            onClick={rightArrowClick}
            ref={rightMainArrow}
            className="slider_main_right_arrow"
          >
            <SliderArrowSvg />
          </div>
        </div>
        <div className="slider_footer">
          <div
            onClick={leftArrowClick}
            ref={leftArrow}
            className="slider_footer_left_arrow"
          >
            <SliderArrowSvg />
          </div>
          <div
            ref={footer_slide_1}
            onClick={() => {
              setSlideIndex(1);
            }}
            className="slider_footer_item"
          >
            <div className="slider_footer_item_text">
              <span>Телеграм-бот и его возможности</span>
              <p>С помощью телеграм-бота, Вы сможете:</p>
              <p>
              1. Оформить перевод за&nbsp;границу в&nbsp;2&nbsp;клика без необходимости скачивания и&nbsp;регистрации
              </p>
              <p>2. Отследить историю переводов и&nbsp;статус перевода</p>
              <p>
              Чтобы оформить перевод, необходимо перейти в&nbsp;телеграм-бота и&nbsp;нажать кнопку &laquo;Отправить перевод&raquo;
              </p>
            </div>
            <div className="slider_body_item_box_img">
              <img src="/assets/slider_mobile/slide1.png" alt="img" />
            </div>

          </div>
          <div
            ref={footer_slide_2}
            onClick={() => {
              setSlideIndex(2);
            }}
            className="slider_footer_item"
          >
            <div className="slider_footer_item_text">
              <span>Создание перевода</span>
              <span>Шаг 1&nbsp;&mdash; Заполните данные для перевода</span>
              <p>
              Выберите страну отправления, укажите сумму и&nbsp;валюту, которую хотите отправить, выберите страну получения, валюту в&nbsp;которой хотите получить перевод и&nbsp;способ получения.
              </p>
            </div>
            <div className="slider_body_item_box_img">
              <img src="/assets/slider_mobile/slide2.png" alt="img" />
            </div>

          </div>
          <div
            ref={footer_slide_3}
            onClick={() => {
              setSlideIndex(3);
            }}
            className="slider_footer_item"
          >
            <div className="slider_footer_item_text">
              <span>Шаг 2&nbsp;&mdash; Введите данные получателя</span>
              <p>
              Введите номер телефона, никнейм-телеграм, фамилию, имя и&nbsp;отчество получателя, как в&nbsp;паспорте.
              </p>
              <p className="_italic">
              * Перед выдачей перевода в&nbsp;офисе, получателю необходимо предъявить паспорт для сверки данных, во&nbsp;избежания того, чтобы перевод не&nbsp;выдали другому лицу.
              </p>
            </div>
            <div className="slider_body_item_box_img">
              <img src="/assets/slider_mobile/slide3.png" alt="img" />
            </div>

          </div>
          <div
            ref={footer_slide_4}
            onClick={() => {
              setSlideIndex(4);
            }}
            className="slider_footer_item"
          >
            <div className="slider_footer_item_text">
              <span>
              Шаг 3&nbsp;&mdash; Проверьте правильность введённых данных и&nbsp;исправьте при необходимости
              </span>
              <p>
              Проверьте правильность введённых данных и&nbsp;нажмите на&nbsp;кнопку &quot;Далее&quot;.

В&nbsp;течение нескольких минут с&nbsp;Вами свяжется служба поддержки TransferDelivery для уточнения условий перевода.</p>
            </div>
            <div className="slider_body_item_box_img">
              <img src="/assets/slider_mobile/slide4.png" alt="img" />
            </div>

          </div>
          <div
            ref={footer_slide_5}
            onClick={() => {
              setSlideIndex(5);
            }}
            className="slider_footer_item"
          >
            <div className="slider_footer_item_text">
              <span>Шаг 4 - Подтвердите перевод</span>
              <p>
              После подтверждения и&nbsp;согласования условий перевода, мы&nbsp;приступим к&nbsp;его выполнению. Вам придёт уведомление от&nbsp;бота о&nbsp;том, что статус перевода &laquo;В процессе&raquo;.
              </p>
              <span>Шаг 5 - Оплатите перевод</span>
              <p>
              Служба поддержки отправит Вам в&nbsp;чат реквизиты для оплаты перевода. В&nbsp;случае, если способ получения на&nbsp;карту или счёт, оплата происходит до&nbsp;получения перевода, если способ получения наличными, то&nbsp;оплата производится в&nbsp;офисе, перед фактическим получением перевода.
              </p>
              <span>Шаг 6 - Получите перевод</span>
              <p>
              Моментально получите перевод наличными или на зарубежную карту в течение 10 минут.
              </p>
            </div>
            <div className="slider_body_item_box_img">
              <img src="/assets/slider_mobile/slide5.png" alt="img" />
            </div>
          </div>
          <div
            ref={footer_slide_6}
            onClick={() => {
              setSlideIndex(6);
            }}
            className="slider_footer_item"
          >
            <div className="slider_footer_item_text">
              <span>Отслеживания статуса перевода</span>
              <p>
              Вы можете отслеживать статус каждого перевода в разделе «Отследить перевод».</p>

            </div>
            <div className="slider_body_item_box_img">
              <img src="/assets/slider_mobile/slide7.png" alt="img" />
            </div>

          </div>
          <div
            ref={footer_slide_7}
            onClick={() => {
              setSlideIndex(7);
            }}
            className="slider_footer_item"
          >
            <div className="slider_footer_item_text">
              <span>История операций</span>
              <p>
              История Ваших переводов всегда доступна в разделе «История переводов».
              </p>
            </div>
            <div className="slider_body_item_box_img">
              <img src="/assets/slider_mobile/slide8.png" alt="img" />
            </div>
          </div>

          <div
            onClick={rightArrowClick}
            ref={rightArrow}
            className="slider_footer_right_arrow"
          >
            <SliderArrowSvg />
          </div>
        </div>
      </div>
    );
};

export default Slider;