import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Layout from "../../layout/Layout";

const BlogItemPage = () => {
  const ref1 = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  const ref4 = useRef()
  const [ref1State, setRef1State] = useState()
  const [ref2State, setRef2State] = useState()
  const [ref3State, setRef3State] = useState()
  const [ref4State, setRef4State] = useState()

  useEffect(() => {

    const ref1Offset = ref1.current.offsetHeight;
    const ref2Offset = ref2.current.offsetHeight;
    const ref3Offset = ref3.current.offsetHeight;
    const ref4Offset = ref4.current.offsetHeight;
    const ref1Position = ref1.current.getBoundingClientRect().top;
    const ref2Position = ref2.current.getBoundingClientRect().top;
    const ref3Position = ref3.current.getBoundingClientRect().top;
    const ref4Position = ref4.current.getBoundingClientRect().top;
    const ref1OffesetPosition = ref1Position - ref1Offset
    const ref2OffesetPosition = ref2Position - ref2Offset
    const ref3OffesetPosition = ref3Position - ref3Offset
    const ref4OffesetPosition = ref4Position - ref4Offset

    setRef1State(ref1OffesetPosition)
    setRef2State(ref2OffesetPosition)
    setRef3State(ref3OffesetPosition)
    setRef4State(ref4OffesetPosition)

  }, [])




  return (
    <Layout>
      <div className="blog_item">
        <div className="blog_item_container pd_conrainer mb_continer">
          <h1 className="title">Как перевести деньги из России за границу в новых реалиях?</h1>
          <span>4 декабря</span>
          <img src="/assets/pages/blog_items/blogimage.png" alt="img" />
          <p className="text">
            Россиянам все сложнее отправлять валюту за рубеж через SWIFT-переводы
            и системы денежных переводов — банки повышают комиссии за такие
            транзакции или теряют возможность их проводить из-за санкционных и
            прочих ограничений, а системы денежных переводов, такие как Unistream
            и KoronaPay сталкиваются с постоянными препятствиями, либо
            ограничениям. В этой статье мы рассмотрим актуальные способы перевода
            средств за границу.
          </p>
          <div className="blog_item_block">
            <p>Из этой статьи вы узнаете:</p>
            <ul>
              <li onClick={() => {
                window.scrollBy({
                  top: ref1State,
                  behavior: 'smooth'
                });
              }}>
                В чем проблема SWIFT-переводов и почему растет недоверие к
                банковской системе?
              </li>
              <li onClick={() => {
                window.scrollBy({
                  top: ref2State,
                  behavior: 'smooth'
                });
              }}>
                Плюсы и минусы систем денежных переводов Unistream, KoronaPay и
                Contact
              </li>
              <li onClick={() => {
                window.scrollBy({
                  top: ref3State,
                  behavior: 'smooth'
                });
              }}>Перевод средств за границу через криптовалюту</li>
              <li onClick={() => {
                window.scrollBy({
                  top: ref4State,
                  behavior: 'smooth'
                });
              }}>Альтернативные способы перевода средств за границу</li>
            </ul>
          </div>
          <h2 ref={ref1} className="title_text">
            В чем проблема SWIFT-переводов и почему растет недоверие к банковской
            системе?
          </h2>
          <p className="text">
            Банковские SWIFT-переводы были долгое время основными инструментами для перемещения капитала за&nbsp;границу. Однако для клиентов российских банков традиционный и&nbsp;простой SWIFT-перевод денег на&nbsp;зарубежный счет стал проблемой. Крупнейшие российские банки (Сбербанк, ВТБ, Альфа-банк, &laquo;Открытие&raquo;, Промсвязьбанк, Совкомбанк, Новикомбанк) из-за блокирующих санкций США потеряли возможность переводить деньги клиентов в&nbsp;долларах и&nbsp;евро. От&nbsp;системы SWIFT были отключены также МКБ и&nbsp;Россельхозбанк. Ряд крупных банков перестали проводить валютные переводы не&nbsp;только за&nbsp;рубеж, но&nbsp;и&nbsp;в&nbsp;другие российские банки, объясняя это рисками блокировки денег зарубежными банками-корреспондентами.<br></br><br></br>

            Сложности с&nbsp;валютными переводами у&nbsp;клиентов неподсанкционных банков возникают в&nbsp;основном на&nbsp;стороне зарубежных банков, которые участвуют в&nbsp;транзакции. Зарубежные финансовые организации разрывают корреспондентские отношения с&nbsp;контрагентами из&nbsp;России, могут отклонить перевод, заморозить его, потребовать доказать легальность происхождения средств. Переводы могут не&nbsp;доходить до&nbsp;адресата неделями и&nbsp;месяцами. В&nbsp;Telegram-чате &laquo;SWIFT&nbsp;&mdash; пропали деньги&raquo; состоит больше 7000&nbsp;участников. Они делятся своими историями о&nbsp;переводах: так, один из&nbsp;пользователей рассказал, что отправленный в&nbsp;мае перевод пришел только в&nbsp;июле. Другой участник утверждает, что перевод, отправленный 15&nbsp;марта из&nbsp;&laquo;Тинькофф&raquo; в&nbsp;грузинский банк, вовсе не&nbsp;пришел к&nbsp;получателю и&nbsp;деньги потерялись.<br></br><br></br>

            Частые задержки, высокие комиссии, постоянные развороты переводов и&nbsp;риск того, что средства вовсе потеряются делают эти методы менее привлекательными для тех, кто ищет быстрый и&nbsp;эффективный способ перемещения денег.<br></br><br></br>

            Тем, кому нужно перевести деньги быстро и&nbsp;гарантировано, приходится искать альтернативные способы.<br></br><br></br>
          </p>
          <h2 ref={ref2} className="title">
            Плюсы и&nbsp;минусы систем денежных переводов Unistream, KoronaPay и&nbsp;Contact
          </h2>
          <p className="text">
            Отправить деньги за&nbsp;границу также можно через системы денежных переводов, однако не&nbsp;все системы дают возможность отправить за&nbsp;рубеж иностранную валюту. Надо понимать, что системы денежных переводов полностью зависят от&nbsp;банков и&nbsp;также подтверждены санкционному давлению. Например, 20&nbsp;июля 2023 года &laquo;Юнистрим&raquo; включили в&nbsp;санкционный список США&nbsp;&mdash; SDN. Теперь с&nbsp;компанией не&nbsp;будет сотрудничать ни&nbsp;один партнер из&nbsp;США, а&nbsp;для прочих контрагентов сотрудничество станет большим репутационным риском. О&nbsp;прекращении работы с&nbsp;&laquo;Юнистримом&raquo; уже заявило два банка из&nbsp;Грузии: Bank of&nbsp;Georgia и&nbsp;TBC. <br></br><br></br>

            Переводы рублей &laquo;Золотой короной&raquo; по-прежнему доступны. Сейчас перевести деньги можно в&nbsp;12&nbsp;стран. Однако получателю могут выдать деньги не&nbsp;по&nbsp;самому выгодному курсу. А&nbsp;если вы&nbsp;отправите перевод самому себе&nbsp;&mdash; операторы отделения могут отказать вам в&nbsp;выдаче денег и&nbsp;сослаться на&nbsp;какие-то правила.<br></br><br></br>

            Contact позволяет переводить деньги только в&nbsp;рублях. В&nbsp;отличие от&nbsp;&laquo;Юнистрима&raquo; и&nbsp;&laquo;Золотой короны&raquo; максимальная сумма перевода ограничена 5000&nbsp;$ или эквивалентом в&nbsp;другой валюте. Для переводов поддерживаются карты, выпущенные только российскими банками,&nbsp;&mdash; совершить перевод с&nbsp;помощью иностранной карты не&nbsp;получится. Переводы через систему &laquo;Contact&raquo; можно получить наличными в&nbsp;банках, либо на&nbsp;карту зарубежного банка-партнера. Однако пользователи жалуются на&nbsp;очень частые проблемы с&nbsp;выдачей переводов, а&nbsp;переводы на&nbsp;карту могут зачислятся в&nbsp;течение 3-х суток.<br></br><br></br>

            Основные минусы данного способа&nbsp;&mdash; лимиты на&nbsp;переводы (в&nbsp;месяц можно отправить до&nbsp;10.000$), высокие курсы при двойной конвертации, задержки и&nbsp;проблемы с&nbsp;выдачей переводов.<br></br><br></br>
          </p>
          <h2 ref={ref3} className="title">Перевод средств за&nbsp;границу через криптовалюту</h2>
          <p className="text">
            Одним из&nbsp;вариантов перевода средств в&nbsp;текущих условиях стали криптовалюты. Это легально, поскольку Закон о&nbsp;цифровых финансовых активах запрещает российским резидентам только принимать криптовалюту в&nbsp;оплату товаров, работ или услуг. В&nbsp;настоящий момент нет никаких ограничений по&nbsp;отправке криптовалюты за&nbsp;рубеж или по&nbsp;ее&nbsp;получению в&nbsp;России, так как оборот цифровых активов сейчас находится в&nbsp;серой зоне.<br></br><br></br>

            Суть проста: находясь в&nbsp;России, покупаем за&nbsp;рубли стейблкоины, а&nbsp;потом по&nbsp;прибытии в&nbsp;другую страну продаем их&nbsp;за&nbsp;местную валюту. Стейблкоины&nbsp;&mdash; это криптовалюты, стоимость которых привязана к&nbsp;той или иной обычной валюте. Например, самый популярный стейблкоин&nbsp;&mdash; USDT, стоимость которого всегда равна стоимости доллара США.<br></br><br></br>

            На&nbsp;данный момент существуют два способа покупки криптовалюты:<br></br><br></br>

            1&nbsp;способ&nbsp;&mdash; покупка криптовалюты через p2p раздел на&nbsp;криптовалютных биржах. Для этого нужно завести аккаунт на&nbsp;бирже и&nbsp;верифицировать профиль. После p2p-покупки криптовалюты, ее&nbsp;выводят за&nbsp;границей в&nbsp;фиатные деньги через криптообменник или карту местного банка. Полная инструкция по&nbsp;покупке криптовалюты через крипто-биржи.<br></br><br></br>

            2&nbsp;способ&nbsp;&mdash; покупка криптовалюты через крипто-обменики. Для этого нужно создать криптокошелёк. Например, Trustwallet или Metamask. Инструкция к&nbsp;установке здесь и&nbsp;здесь соответсвенно.<br></br><br></br>


            Однако данный способ содержит ряд подводных камней, особенно для тех, кто ни&nbsp;разу не&nbsp;сталкивался с&nbsp;криптовалютой. Многие биржи под давлением санкций ограничивают свою работу. Так, например, крупнейшая криптобиржа Binance заявила об&nbsp;уходе из&nbsp;России, а&nbsp;такие биржи как Bybit и&nbsp;OKX убрали из&nbsp;способ оплаты подсанкционные Российские банки.<br></br><br></br>

            Поэтому не&nbsp;держите крупные суммы на&nbsp;биржах, а&nbsp;сразу их&nbsp;обменивайте. Либо переводите деньги на&nbsp;криптовалютные кошельки, которые заблокировать не&nbsp;смогут.

            Еще один недостаток способа&nbsp;&mdash; банк может заблокировать вашу карту, если посчитает транзакции подозрительными. Чтобы транзакция не&nbsp;попала в&nbsp;разряд &laquo;подозрительных&raquo;, в&nbsp;примечании к&nbsp;платежу не&nbsp;указывайте ничего, что связано с&nbsp;криптовалютой. Например, не&nbsp;пишите &laquo;за&nbsp;крипту&raquo;, &laquo;обмен&raquo; и&nbsp;так далее.<br></br><br></br>

            Но&nbsp;минусы есть не&nbsp;только у&nbsp;бирж, но&nbsp;и&nbsp;у&nbsp;онлайн-обменников&nbsp;&mdash; высокий курс покупки валюты. На&nbsp;покупке USDT на&nbsp;p2p и&nbsp;на&nbsp;последующей конвертации в&nbsp;зарубежную фиатную валюту можно потерять до&nbsp;20% от&nbsp;суммы.<br></br><br></br>

            Ну&nbsp;и&nbsp;пожалуй самый главный минус&nbsp;&mdash; это мошенники, которые просто кишат в&nbsp;данной сфере. Поэтому будьте очень внимательны, всегда покупайте криптовалюту только у&nbsp;проверенных пользователей (мерчантов) с&nbsp;большим количеством проведённых сделок и&nbsp;положительных отзывов. Онлайн и&nbsp;офлайн обменики отбирайте на&nbsp;проверенных ресурсах, таких как bestchange.<br></br><br></br>
          </p>
          <h2 ref={ref4} className="title">Альтернативные способы перевода средств за&nbsp;границу</h2>
          <p className="text">
            Нельзя не&nbsp;согласится с&nbsp;тем, что санкции показали насколько зависима от&nbsp;банков сфера международных переводов, которая в&nbsp;данных обстоятельствах демонстрирует свою неэффективность. Альтернативные способы, такие как криптовалюта содержат много подводных камней, которые могут сулить потерей всех денежных средств.<br></br><br></br>

            Сфера международных переводов требует инновационных решений, которые исключат все возможные риски и&nbsp;проблемы связанные с&nbsp;переводами средств за&nbsp;границу. В&nbsp;этой связи появляются альтернативные сервисы, такие как TransferDelivery, которая помогает решить проблемы заморозок, разворотов и&nbsp;потерей денег, с&nbsp;чем часто сталкиваются Россияне, отправляя переводы из&nbsp;банков или систем денежных переводов. TransferDelivery&nbsp;&mdash; это сервис денежных переводов, через который можно отправить деньги из&nbsp;России в&nbsp;страны СНГ, Европу и&nbsp;Ближнего Востока.<br></br><br></br>

            Одним из&nbsp;ключевых преимуществ TransferDelivery является глобальная сеть партнеров. Каждый партнер&nbsp;&mdash; это тщательно отобранный обменник, обладающий лицензией на&nbsp;работу в&nbsp;своей стране, высокой репутацией и&nbsp;доверием. Это обеспечивает возможность отправки средств в&nbsp;любую точку мира, с&nbsp;уверенностью в&nbsp;надежности и&nbsp;безопасности операции.<br></br><br></br>

            Система TransferDelivery предоставляет пользователю возможность осуществлять переводы через Telegram, обеспечивая при этом анонимность и&nbsp;конфиденциальность. Отсутствие необходимости в&nbsp;регистрации значительно упрощает процесс и&nbsp;предотвращает ограничения и&nbsp;лимиты на&nbsp;переводы часто налагаемые банками или сервисами денежных переводов.<br></br><br></br>

            Сервис не&nbsp;требует оплату наперёд, оплата перевода производится в&nbsp;офисе при непосредственном получении денег, что обеспечивает дополнительную безопасность операций и&nbsp;уверенности клиента в&nbsp;получении средств.<br></br><br></br>

            Учитывая трудности, с&nbsp;которыми сталкиваются другие способы, TransferDelivery представляет собой перспективное решение для тех, кто ищет быстрый, надежный и&nbsp;безопасный способ отправки средств за&nbsp;границу.<br></br><br></br>
          </p>
        </div>
      </div>
    </Layout>

  );
};
export default BlogItemPage;
