import React from "react";
import "./index.css";

const MainPageModuleCards = () => {
  return (
    <div className="container_cards mb_container">
      <div className="cards_wrapper">
        <div className="cards_title_wrapper title_mb">
          <h2 className="title">Денежные переводы по&nbsp;всему миру</h2>
          <p className="text cards_title_text">
            Отправляйте платежи без ограничений в&nbsp;любую страну в&nbsp;любой
            валюте!
          </p>
        </div>
        <div className="cards_content">
          <ul className="cards_content_items">
            <li className="cards_content_item">
              <div className="cards_content_item_img"></div>
              <p className="cards_content_item_text text">
                Отправляйте деньги себе или своим родным и&nbsp;близким.
              </p>
            </li>
            <li className="cards_content_item">
              <div className="cards_content_item_img"></div>
              <p className="cards_content_item_text text">
                Пополняйте баланс Вашей зарубежной карты или счёта.
              </p>
            </li>
            <li className="cards_content_item">
              <div className="cards_content_item_img"></div>
              <p className="cards_content_item_text text">
                Оплачивайте зарплату сотрудникам за&nbsp;границей.
              </p>
            </li>
            <li className="cards_content_item">
              <div className="cards_content_item_img"></div>
              <p className="cards_content_item_text text">
                Покупайте и&nbsp;продавайте криптовалюту за&nbsp;валюту
                в&nbsp;более 35&nbsp;странах.
              </p>
            </li>
            <li className="cards_content_item">
              <div className="cards_content_item_img"></div>
              <p className="cards_content_item_text text">
                Оплачивайте товары, недвижимость или автомобили
                за&nbsp;границей.
              </p>
            </li>
            <li className="cards_content_item">
              <div className="cards_content_item_img"></div>
              <p className="cards_content_item_text text">
                Принимайте и&nbsp;совершайте оплату от&nbsp;клиентов или
                поставщиков из-за рубежа.
              </p>
            </li>
          </ul>
        </div>
   
            <div className="cards_footer">
          <div className="cards_footer_wrapper">
              <ul className="cards_footer_items">
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_1"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_2"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_3"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_4"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_5"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_6"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_7"></div>
                </li>
              </ul>
              <ul aria-hidden='true'  className="cards_footer_items">
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_1"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_2"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_3"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_4"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_5"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_6"></div>
                </li>
                <li className="cards_footer_item">
                  <div className="cards_footer_item_img cards_footer_item_img_7"></div>
                </li>
              </ul>
          </div>
        </div>  

      
      </div>
    </div>
  );
};

export default MainPageModuleCards;
