import React, { useRef } from "react";
import "./index.css";
import AccordionArrowSvg from "../../elements/AccordionArrowSvg/AccordionArrowSvg";

const MainPageModuleEight = () => {
  const p1 = useRef();
  const p2 = useRef();
  const p3 = useRef();
  const p4 = useRef();
  const p5 = useRef();
  const p6 = useRef();
  const p7 = useRef();
  const p8 = useRef();

  const a1 = useRef();
  const a2 = useRef();
  const a3 = useRef();
  const a4 = useRef();
  const a5 = useRef();
  const a6 = useRef();
  const a7 = useRef();
  const a8 = useRef();

  return (
    <div className="module_eight_container mb_container">
      <h2 className="title mb_title">Часто задаваемые{"\u00A0"}вопросы</h2>
      <div className="module_eight_block">
        <div className="module_eight_one">
          <div
            onClick={() => {
              p1.current.style.display === "block"
                ? (p1.current.style.display = "none")
                : (p1.current.style.display = "block");
              a1.current.style.transform === "rotate(180deg)"
                ? (a1.current.style.transform = "rotate(0deg)")
                : (a1.current.style.transform = "rotate(180deg)");
            }}
            className="module_eight_one_item_block"
          >
            <div className="module_eight_one_item">
              <div className="module_eight_one_item_title">
                <div className="text">Что такое TransferDelivery?</div>
                <div ref={a1}>
                  <AccordionArrowSvg />
                </div>
              </div>

              <p ref={p1} className="text module_eight_one_item_text">
                TransferDelivery — это быстрая, удобная и безопасная
                альтернатива банковским переводам и системам денежных переводов.
                <br />
                <br />
                Сотни наших клиентов ежедневно переводят средства между Россией
                и 35 зарубежными странами. Высокая надежность, простота и
                скорость транзакций достигается благодаря развитой
                инфраструктуре компании, использованию безопасных счетов и
                офисов-партнёров по всему Миру.
                <br />
                <br />
                Подробнее о том, как работает сервис в нашем блоге.
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              p2.current.style.display === "block"
                ? (p2.current.style.display = "none")
                : (p2.current.style.display = "block");
              a2.current.style.transform === "rotate(180deg)"
                ? (a2.current.style.transform = "rotate(0deg)")
                : (a2.current.style.transform = "rotate(180deg)");
            }}
            className="module_eight_one_item_block"
          >
            <div className="module_eight_one_item">
              <div className="module_eight_one_item_title">
                <div className="text">
                  Как отправить перевод через Телеграм приложение?
                </div>
                <div ref={a2}>
                  <AccordionArrowSvg />
                </div>
              </div>
              <p ref={p2} className="text module_eight_one_item_text">
                Для отправки перевода потребуется выполнить следующие шаги:
                <br />
                <br />
                1. Перейдите в приложение https://t.me/TransferDelivery_bot
                <br />
                <br />
                2. Выберите страну отправления, укажите сумму и валюту, которую
                хотите отправить, выберите страну получения, валюту в которой
                хотите получить перевод и способ получения.
                <br />
                <br />
                3. Заполните данные получателя. Введите номер телефона, фамилию,
                имя и отчество получателя, как в паспорте.
                <br />
                <br />
                4. Проверьте правильность введённых данных и исправьте при
                необходимости.
                <br />
                <br />
                5. Нажмите на кнопку «Далее». На экране появится номер перевода,
                состоящего из 6-ти цифр и статус перевода «Ожидает
                подтверждения». В течение нескольких минут с Вами свяжется
                служба поддержки для его подтверждения и уточнения условий
                перевода.
                <br />
                <br />
                6. После подтверждения и согласования условий перевода, мы
                приступим к его выполнению. Вам придёт уведомление от бота о
                том, что статус перевода «В процессе».
                <br />
                <br />
                7. Оплатите перевод. Служба поддержки отправит Вам в чат
                реквизиты для оплаты перевода. В случае, если способ получения
                на карту или счёт, оплата происходит до получения перевода, если
                способ получения наличными, то оплата производится в офисе,
                перед фактическим получением перевода.
                <br />
                <br />
                8. Получите перевод. После успешного выполнения перевода, служба
                поддержки отправит чек о выполнении платежа.
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              p3.current.style.display === "block"
                ? (p3.current.style.display = "none")
                : (p3.current.style.display = "block");
              a3.current.style.transform === "rotate(180deg)"
                ? (a3.current.style.transform = "rotate(0deg)")
                : (a3.current.style.transform = "rotate(180deg)");
            }}
            className="module_eight_one_item_block"
          >
            <div className="module_eight_one_item">
              <div className="module_eight_one_item_title">
                <div className="text">Как отправить перевод через сайт?</div>
                <div ref={a3}>
                  <AccordionArrowSvg />
                </div>
              </div>
              <div ref={p3} className="text module_eight_one_item_text">
                {/* <ul>
                  <li className="text">
                    Минимальная сумма перевода способом «На{"\u00A0"}карту»
                    составляет 20{"\u00A0"}000 рублей.
                  </li>
                  <li className="text">
                    Минимальная сумма перевода способом «Наличными{"\u00A0"}в
                    {"\u00A0"}офисе» составляет 40{"\u00A0"}000 рублей.
                  </li>
                  <li className="text">
                    Максимальная сумма перевода способом «На{"\u00A0"}карту»
                    составляет 300{"\u00A0"}000 рублей.
                  </li>
                </ul> */}
                1. Перейдите на главную страницу сайта
                https://transferdelivery.net/
                <br />
                <br />
                2. Выберите страну отправления, укажите сумму и валюту, которую
                хотите отправить, выберите страну получения, валюту в которой
                хотите получить перевод и способ получения.
                <br />
                <br />
                3. Укажите контакт для связи. Он будет необходим для уточнения
                условий перевода.
                <br />
                <br />
                4. Нажмите на кнопку «Отправить». На экране появится номер
                перевода, состоящего из 6-ти цифр. В течение нескольких минут с
                Вами свяжется служба поддержки для уточнения условий перевода.
                <br />
                <br />
                5. После подтверждения и согласования условий перевода мы
                приступим к его выполнению.
                <br />
                <br />
                6. Оплатите перевод. Служба поддержки отправит Вам в чат
                реквизиты для оплаты перевода. В случае, если способ получения
                на карту или счёт, оплата происходит до получения перевода, если
                способ получения наличными, то оплата производится в офисе,
                перед фактическим получением перевода.
                <br />
                <br />
                7. Получите перевод. После успешного выполнения перевода, служба
                поддержки отправит чек о выполнении платежа.
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              p4.current.style.display === "block"
                ? (p4.current.style.display = "none")
                : (p4.current.style.display = "block");
              a4.current.style.transform === "rotate(180deg)"
                ? (a4.current.style.transform = "rotate(0deg)")
                : (a4.current.style.transform = "rotate(180deg)");
            }}
            className="module_eight_one_item_block"
          >
            <div className="module_eight_one_item">
              <div className="module_eight_one_item_title">
                <div className="text">
                  Безопасно ли отправлять деньги через TransferDelivery?
                </div>
                <div ref={a4}>
                  <AccordionArrowSvg />
                </div>
              </div>
              <p ref={p4} className="text module_eight_one_item_text">
                Мы делаем все, что в наших силах, чтобы обеспечить полную
                безопасность каждого перевода. Мы не банк, не обменник и не
                электронный кошелек, и мы никогда не держим никаких средств.
                <br />
                <br />
                Кроме того, наличные переводы TransferDelivery оплачиваются и
                выдаются во время встречи с получателем в офисах у наших
                партнёров-обменников, которые заслужили высокую репутацию и
                доверие.
              </p>
            </div>
          </div>

          <div
            onClick={() => {
              p5.current.style.display === "block"
                ? (p5.current.style.display = "none")
                : (p5.current.style.display = "block");
              a5.current.style.transform === "rotate(180deg)"
                ? (a5.current.style.transform = "rotate(0deg)")
                : (a5.current.style.transform = "rotate(180deg)");
            }}
            className="module_eight_one_item_block"
          >
            <div className="module_eight_one_item">
              <div className="module_eight_one_item_title">
                <div className="text">Какая комиссия у сервиса?</div>
                <div ref={a5}>
                  <AccordionArrowSvg />
                </div>
              </div>
              <p ref={p5} className="text module_eight_one_item_text">
                Комиссия TransferDelivery зависит от суммы и направления
                перевода и уточняется у службы поддержки после его оформления.
                Эта комиссия позволяет нам поддерживать работу сервиса и
                обеспечивать безопасность денежных средств.
              </p>
            </div>
          </div>

          <div
            onClick={() => {
              p6.current.style.display === "block"
                ? (p6.current.style.display = "none")
                : (p6.current.style.display = "block");
              a6.current.style.transform === "rotate(180deg)"
                ? (a6.current.style.transform = "rotate(0deg)")
                : (a6.current.style.transform = "rotate(180deg)");
            }}
            className="module_eight_one_item_block"
          >
            <div className="module_eight_one_item">
              <div className="module_eight_one_item_title">
                <div className="text">
                  Какая максимальная и минимальная сумма перевода?
                </div>
                <div ref={a6}>
                  <AccordionArrowSvg />
                </div>
              </div>
              <p ref={p6} className="text module_eight_one_item_text">
                Минимальная сумма перевода составляет 500 USD в эквиваленте
                других валют.
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              p7.current.style.display === "block"
                ? (p7.current.style.display = "none")
                : (p7.current.style.display = "block");
              a7.current.style.transform === "rotate(180deg)"
                ? (a7.current.style.transform = "rotate(0deg)")
                : (a7.current.style.transform = "rotate(180deg)");
            }}
            className="module_eight_one_item_block"
          >
            <div className="module_eight_one_item">
              <div className="module_eight_one_item_title">
                <div className="text">Сколько времени занимает перевод?</div>
                <div ref={a7}>
                  <AccordionArrowSvg />
                </div>
              </div>
              <p ref={p7} className="text module_eight_one_item_text">
                Скорость перевода зависит от выбранного способа получения и
                времени, необходимого для проведения транзакции. В большинстве
                случаев, переводы через TransferDelivery обрабатываются
                мгновенно или в течение нескольких минут.
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              p8.current.style.display === "block"
                ? (p8.current.style.display = "none")
                : (p8.current.style.display = "block");
              a8.current.style.transform === "rotate(180deg)"
                ? (a8.current.style.transform = "rotate(0deg)")
                : (a8.current.style.transform = "rotate(180deg)");
            }}
            className="module_eight_one_item_block"
          >
            <div className="module_eight_one_item">
              <div className="module_eight_one_item_title">
                <div className="text">
                  В течении какого времени можно получить перевод?
                </div>
                <div ref={a8}>
                  <AccordionArrowSvg />
                </div>
              </div>
              <p ref={p8} className="text module_eight_one_item_text">
                Все переводы обрабатываются и выдаются с 10:00 до 21:00 по МСК.
                Переводы, оформленные после 21:00 обрабатываются на следующий
                день. Если перевод не выдан получателю в течении суток или не
                подтверждён, он автоматически аннулируется.
              </p>
            </div>
          </div>
        </div>
        <div className="module_eight_two">
          <img src="/assets/pages/main/questions/phone.png" alt="img" />
        </div>
      </div>
    </div>
  );
};

export default MainPageModuleEight;
