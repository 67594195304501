import React from "react";

const AccordionArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="15"
      viewBox="0 0 30 15"
      fill="none"
    >
      <path
        d="M2.52951 0.403221C2.38639 0.274458 2.21803 0.173795 2.03404 0.106978C1.85006 0.0401611 1.65405 0.00849819 1.45721 0.0137997C1.26038 0.0191011 1.06656 0.0612621 0.88684 0.137875C0.707117 0.214487 0.545003 0.324051 0.409755 0.460311C0.274507 0.596571 0.168774 0.75686 0.0985921 0.932024C0.02841 1.10719 -0.00484653 1.2938 0.000721721 1.4812C0.00628997 1.6686 0.0505737 1.85312 0.131045 2.02422C0.211515 2.19533 0.326598 2.34967 0.46972 2.47843L13.9618 14.61C14.2401 14.8605 14.6087 15.0001 14.9917 15.0001C15.3747 15.0001 15.7433 14.8605 16.0216 14.61L29.5152 2.47843C29.6614 2.35052 29.7795 2.19621 29.8626 2.02448C29.9457 1.85274 29.9921 1.667 29.9992 1.47804C30.0062 1.28908 29.9738 1.10067 29.9037 0.923757C29.8336 0.74684 29.7273 0.584943 29.591 0.447471C29.4546 0.309998 29.2909 0.199688 29.1094 0.122951C28.9279 0.0462132 28.7322 0.00457764 28.5336 0.000460625C28.3351 -0.00365639 28.1377 0.0298281 27.9528 0.0989695C27.768 0.168111 27.5994 0.27153 27.4569 0.403221L14.9917 11.6099L2.52951 0.403221Z"
        fill="#666667"
      />
    </svg>
  );
};

export default AccordionArrowSvg;
