import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";

const MainPageModuleThree = () => {
  const navigate = useNavigate()
  const item1 = useRef()
  const item2 = useRef()
  const item3 = useRef()
  const item4 = useRef()
  const slide1 = useRef()
  const slide2 = useRef()
  const slide3 = useRef()
  const slide4 = useRef()
  const [slide, setSlide] = useState(1)
  useEffect(() => {
    if (slide === 1) {
      item1.current.classList.add('active')
      slide1.current.style.display = 'block'
      slide2.current.style.display = 'none'
      slide3.current.style.display = 'none'
      slide4.current.style.display = 'none'
    }
    if (slide === 2) {
      slide1.current.style.display = 'none'
      slide2.current.style.display = 'block'
      slide3.current.style.display = 'none'
      slide4.current.style.display = 'none'
    }
    if (slide === 3) {
      slide1.current.style.display = 'none'
      slide2.current.style.display = 'none'
      slide3.current.style.display = 'block'
      slide4.current.style.display = 'none'
    }
    if (slide === 4) {
      slide1.current.style.display = 'none'
      slide2.current.style.display = 'none'
      slide3.current.style.display = 'none'
      slide4.current.style.display = 'block'
    }
  })

  return (
    <div className="module_three_container mb_container">
      <div className="module_three_block_one">
        <div className="title mb_title">
          Отправляй деньги за&nbsp;границу быстро и&nbsp;безопасно
        </div>
        <div ref={item1}
          onClick={() => {
            item1.current.classList.add('active')
            item2.current.classList.remove('active')
            item3.current.classList.remove('active')
            item4.current.classList.remove('active')
            setSlide(1)

          }}
          className="module_three_block_one_item">
          <span className="module_three_block_one_item_id text">01</span>
          <div className="module_three_block_one_item_block">
            <div className="module_three_block_one_item_title">
              <span className="title">
                Оформить перевод
              </span>
            </div>

            <span className="module_three_block_one_item_description text">
              Оформите перевод на&nbsp;сайте или в&nbsp;телеграм&nbsp;боте
            </span>
          </div>
        </div>
        <div
          onClick={() => {
            item1.current.classList.remove('active')
            item2.current.classList.add('active')
            item3.current.classList.remove('active')
            item4.current.classList.remove('active')
            setSlide(2)

          }}
          ref={item2} className="module_three_block_one_item">
          <span className="module_three_block_one_item_id text">02</span>
          <div className="module_three_block_one_item_block">
            <div className="module_three_block_one_item_title">
              <span className="title">
                Подтвердите перевод
              </span>
            </div>
            <span className="module_three_block_one_item_description text">
              После оформления перевода с&nbsp;Вами свяжется служба поддержки для его подтверждения
            </span>
          </div>
        </div>
        <div
          onClick={() => {
            item1.current.classList.remove('active')
            item2.current.classList.remove('active')
            item3.current.classList.add('active')
            item4.current.classList.remove('active')
            setSlide(3)

          }}
          ref={item3} className="module_three_block_one_item">
          <span className="module_three_block_one_item_id text">03</span>
          <div className="module_three_block_one_item_block">
            <div className="module_three_block_one_item_title">
              <span className="title">
                Оплатите перевод
              </span>
            </div>
            <span className="module_three_block_one_item_description text">
              Оплатите перевод удобным для Вас способом
            </span>
          </div>
        </div>
        <div
          onClick={() => {
            item1.current.classList.remove('active')
            item2.current.classList.remove('active')
            item3.current.classList.remove('active')
            item4.current.classList.add('active')
            setSlide(4)
          }}
          ref={item4} className="module_three_block_one_item">
          <span className="module_three_block_one_item_id text">04</span>
          <div className="module_three_block_one_item_block">
            <div className="module_three_block_one_item_title">
              <span className="title">
                Получите перевод
              </span>
            </div>
            <span className="module_three_block_one_item_description text">
              Моментально получите деньги наличными или на&nbsp;зарубежную карту в&nbsp;течение 10&nbsp;минут
            </span>
          </div>
        </div>
      </div>
      <div className="module_three_block_two">

        <div
          onClick={() => {
            navigate('/how-it-works')
          }}
          id="module_three_block_two_title">Как это работает?</div>
        <img id="body" src="/assets/Body.png" alt="img" />
        <img className="item" ref={slide1} src="/assets/photo.jpg" alt="mp4" />
        <img className="item" ref={slide2} src="/assets/photo.jpg" alt="mp4" />
        <img className="item" ref={slide3} src="/assets/photo.jpg" alt="img" />
        <img className="item" ref={slide4} src="/assets/photo.jpg" alt="img" />
      </div>
    </div>
  );
};

export default MainPageModuleThree;
