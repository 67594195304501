import React, { useContext, useEffect, useRef, useState } from "react";
import "./index.css";
import SelectArrowSvg from "../SelectArrowSvg/SelectArrowSvg";
import ArrowSvg from "../ArrowSvg/ArrowSvg";
import { getCountries, getCountriesProperties } from "../../http/transactionAPI";
import { Countries, Currencies, IsOpenCountry, IsOpenCurrency, IsOpenMethod, IsOpenSendCountry, IsOpenSendCurrency, Methods, SendCountries } from "../..";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const SelectMethod = ({redMethod}) => {
  const [country, setCountry] = useContext(Countries);
  const [method, setMethod] = useContext(Methods);

  const [title, setTitle] = useState("Выберите способ получения");
  const [isOpenSendCountry, setIsOpenSendCountry] = useContext(IsOpenSendCountry);
  const [isOpenMethod, setIsOpenMethod] = useContext(IsOpenMethod);

  const [isOpenSendCurrency, setIsOpenSendCurrency] = useContext(IsOpenSendCurrency);
  const [isOpenCountry, setIsOpenCountry] = useContext(IsOpenCountry);
  const [isOpenCurrency, setIsOpenCurrency] = useContext(IsOpenCurrency);
const ref = useRef()
  const [array, setArray] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const currencies = [];
  const currency_name = [];
  useEffect(() => {
    setIsLoad(true)
    if (country !== "Выберите страну получения")
      getCountriesProperties(country.title).then((response) => {
        response.country.method_transactions.forEach((item) => {
          currencies.push(item);
        });
        currencies.forEach((item) => {
          currency_name.push(item.title);
          setArray(currency_name)
          setTitle("Выберите способ получения")
        });
        setIsLoad(false)
      });
  }, [country])

  useEffect(() => {
    // if (isOpenCountry) setIsOpenCurrency(false);

    // if (isOpenSendCountry) setIsOpenCurrency(false);
    // if (isOpenMethod) setIsOpenCurrency(false);
    // if (isOpenSendCurrency) setIsOpenCurrency(false);
    if (isOpenMethod) setIsOpenSendCountry(false);
    if (isOpenMethod) setIsOpenSendCurrency(false);
    if (isOpenMethod) setIsOpenCurrency(false);
    if (isOpenMethod) setIsOpenCountry(false);
    if (redMethod) {
      ref.current.style.border = '3px solid red'
    } else {
      ref.current.style.border = 'none'

    }
    if (title !== "Выберите способ получения") {
      ref.current.style.border = 'none'
   }
  })

  return (
    <div
    ref={ref}
      onClick={country !== 'Выберите страну получения' ?
        isOpenMethod ? () => { setIsOpenMethod(false) } : () => { setIsOpenMethod(true) }
        : () => toast.error("Выберите страну получения", {
          position: toast.POSITION.BOTTOM_RIGHT,
        })}
      className="select_btn_container">
      <div className="select_container__title_method">{title}</div>
      {isOpenMethod ? (
        <div className="select_options">
          {isLoad ? (
            <span className="spinner"></span>
          ) : (
            array.map((item) => {
              if (item === title) {
                return (
                  <div
                    className="select_optionArrow"
                    key={item}
                    onClick={() => { setTitle(item); setMethod(item) }}
                  >
                    <SelectArrowSvg />
                    {item}
                  </div>
                );
              } else {
                return (
                  <div
                    className="select_option"
                    key={item}
                    onClick={() => { setTitle(item); setMethod(item) }}
                  >
                    {item}
                  </div>
                );
              }
            })
          )}
        </div>
      ) : (
        ""
      )}
      <ArrowSvg />
    </div>
  );
};

export default SelectMethod;
