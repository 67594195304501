import React, { useEffect, useRef } from "react";
import "./index.css";

import Navbar from "../modules/Navbar/Navbar";
import Footer from "../modules/Footer/Footer";
import HomeSvg from "../elements/HomeSvg/HomeSvg";
import HowItWorkSvg from "../elements/HowItWorkSvg/HowItWorkSvg";
import BlogSvg from "../elements/BlogSvg/BlogSvg";
import OtziviSvg from "../elements/OtziviSvg/OtziviSvg";
import ContactsSvg from "../elements/ContactsSvg/ContactsSvg";
import { useNavigate } from "react-router-dom";

const Layout = ({ children }) => {
  const navigate = useNavigate();

  return (
    <div className="main_container">
      <Navbar />

      {children}

      <Footer />
    </div>
  );
};

export default Layout;
