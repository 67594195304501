import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import Layout from "../../layout/Layout";
import Attention from "../../elements/Attention/Attention";
import SliderArrowSvg from "../../elements/SliderArrowSvg/SliderArrowSvg";
import TelegramSvg from "../../elements/TelegramSvg/TelegramSvg";
import Slider from "../../modules/Slider/Slider";

const HowItWorks = () => {

  return (
    <Layout>
      <div className="how_it_works">
        <div className="how_it_works_container pd_conrainer">
          <h1 className="how_it_works_title main_title mb_title title_mb_left">
            Как устроен процесс работы с <span className="color_blue">TransferDelivery</span> ?
          </h1>
          <h2 className="how_it_works_title_h2 text mb_title title_mb_left">
            Переводы TransferDelivery можно оформить на&nbsp;главной странице сайта или&nbsp;через телеграм-бота
          </h2>
          <div className="how_it_works_slider_mobile">
            <div className="how_it_works_slider_mobile_item">
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide1.png" />
              </div>
              <h2 className="title">Телеграм-бот и его возможности</h2>
              <p className="text">С помощью телеграм бота, Вы сможете:</p>
              <p className="text">
                1. Оформить перевод за границу в 2 клика без{'\u00A0'}необходимости
                скачивания и регистрации
              </p>
              <p className="text">
                Чтобы оформить перевод, Вам нужно перейти в Телеграм-бота и
                нажать кнопку «Отправить перевод»
              </p>
            </div>
            <div className="how_it_works_slider_mobile_item_dark">
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide2.png" />
              </div>
              <h2 className="title">Создание перевода</h2>
              <h3 className="title">Шаг 1&nbsp;&mdash; Заполните данные для перевода</h3>
              <p className="text">
                Выберите страну отправления, укажите сумму и&nbsp;валюту, которую хотите отправить, выберите страну получения, валюту в&nbsp;которой хотите получить перевод и&nbsp;способ получения.
              </p>
            </div>
            <div className="how_it_works_slider_mobile_item">
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide3.png" />
              </div>
              <h3 className="title">Шаг 2&nbsp;&mdash; Введите данные получателя</h3>
              <p className="text">
                Введите номер телефона, никнейм-телеграм, фамилию, имя и&nbsp;отчество получателя, как в&nbsp;паспорте.
              </p>
              <p className="text _italic">
                * Перед выдачей перевода в&nbsp;офисе, получателю необходимо предъявить паспорт для сверки данных, во&nbsp;избежания того, чтобы перевод не&nbsp;выдали другому лицу.
              </p>
            </div>
            <div className="how_it_works_slider_mobile_item_dark">
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide4.png" />
              </div>
              <h3 className="title">
                Шаг 3&nbsp;&mdash; Проверьте правильность введённых данных и&nbsp;исправьте при необходимости
              </h3>
              <p className="text">
                Проверьте правильность введённых данных и&nbsp;нажмите на&nbsp;кнопку &quot;Далее&quot;.
                В&nbsp;течение нескольких минут с&nbsp;Вами свяжется служба поддержки TransferDelivery для уточнения условий перевода.
              </p>
            </div>
            <div className="how_it_works_slider_mobile_item">
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide5.png" />
              </div>
              <h3 className="title">Шаг 4&nbsp;&mdash; Подтвердите перевод</h3>
              <p className="text">
                После подтверждения и&nbsp;согласования условий перевода, мы&nbsp;приступим к&nbsp;его выполнению. Вам придёт уведомление от&nbsp;бота о&nbsp;том, что статус перевода &laquo;В процессе&raquo;.
              </p>
              <h3 className="title">Шаг 5&nbsp;&mdash; Оплатите перевод</h3>
              <p className="text">
                Служба поддержки отправит Вам в&nbsp;чат реквизиты для оплаты перевода. В&nbsp;случае, если способ получения на&nbsp;карту или счёт, оплата происходит до&nbsp;получения перевода, если способ получения наличными, то&nbsp;оплата производится в&nbsp;офисе, перед фактическим получением перевода.
              </p>
              <h3 className="title">Шаг 6&nbsp;&mdash; Получите перевод</h3>
              <p className="text">
                Моментально получите перевод наличными или на&nbsp;зарубежную карту в&nbsp;течение 10&nbsp;минут. После успешного выполнения перевода, служба поддержки отправит чек о&nbsp;выполнении платежа.
              </p>
            </div>
            <div className="how_it_works_slider_mobile_item_dark">
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide7.png" />
              </div>
              <h2 className="title">Отслеживания статуса перевода</h2>
              <p className="text">
                Вы&nbsp;можете отслеживать статус каждого перевода в&nbsp;разделе &quot;Отследить перевод&quot;.
              </p>
            </div>
            <div className="how_it_works_slider_mobile_item">
              <div className="slider_body_item_box_img">
                <img src="/assets/slider_mobile/slide8.png" />
              </div>
              <h2 className="title">История операций</h2>
              <p className="text">
                История Ваших переводов всегда доступна в&nbsp;разделе &quot;История переводов&quot;.
              </p>
            </div>

          </div>
          <Slider />
          <h2 className="title mb_title title_mb_left">
            Остерегайтесь мошенников!
          </h2>
          <div className="how_it_works_section_item">
            <div>
              <Attention />
            </div>
            <p className="text">
              Никогда не сообщайте свой номер перевода посторонним лицам.
            </p>
          </div>
          <div className="how_it_works_section_item">
            <div>
              <Attention />
            </div>
            <p className="text">
              Остерегайтесь мошенников, которые выдают себя за представителей
              нашей службы поддержки. Обращайте внимание, что Вы общаетесь по
              официальным ссылкам. У нас нет никакой дополнительной поддержки
              или их представителей!
            </p>
          </div>
          <div className="how_it_works_section_item">
            <div>
              <Attention />
            </div>
            <p className="text">
              Используйте только официальные ссылки TransferDelivery. Другие
              ссылки на нас являются мошенничеством! Игнорируйте их и сообщайте
              в официальную поддержку.
            </p>
          </div>

          <div className="mt_container">
            <h2 className="title mb_title title_mb_left">
              Официальные ссылки на наши ресурсы
            </h2>
            <div className="how_it_works_official_links">
              <div className="how_it_works_official_links_item">

                <div className="how_it_works_official_links_item_body">
                  <h3 className="how_it_works_official_links_item_title title">
                    Официальная служба поддержки TransferDelivery
                  </h3>
                  <div className="how_it_works_official_links_item_text  text">
                    Занимается подтверждением переводов, обработками заявок, консультирует по&nbsp;работе сервиса <a href="https://transfer-dlivery.ru/">TransferDelivery</a>.
                  </div>
                  <div className="how_it_works_official_links_item_text text">
                    Имя{'\u00A0'}пользователя: <a href="https://t.me/TransferDeliverySupport">@TransferDeliverySupport</a>
                  </div>
                  <form action="https://t.me/TransferDeliverySupport">
                    <button className="btn"><TelegramSvg />Открыть профиль</button>
                  </form>
                </div>
                <div className="how_it_works_official_links_item_img_wrapper official_link_1">

                </div>

              </div>
              <div className="how_it_works_official_links_item">
                <div className="how_it_works_official_links_item_body">
                  <div className="how_it_works_official_links_item_title title">
                    Группа с новостями TransferDelivery
                  </div>
                  <div className="how_it_works_official_links_item_text text">
                    Здесь мы делимся актуальной информацией о работе сервиса <a href="https://transfer-dlivery.ru/">TransferDelivery</a>, новостями и обновлениями.
                  </div>
                  <div className="how_it_works_official_links_item_text text">
                    Ссылка{'\u00A0'}на{'\u00A0'}канал: <a href="https://t.me/transferdelivery">@transferdelivery</a>
                  </div>
                  <form action="https://t.me/transferdelivery">
                    <button className="btn"><TelegramSvg />Открыть профиль</button>
                  </form>
                </div>
                <div className="how_it_works_official_links_item_img_wrapper official_link_2">

                </div>
              </div>
              <div className="how_it_works_official_links_item">
                <div className="how_it_works_official_links_item_body">
                  <div className="how_it_works_official_links_item_title title">
                    Бот для оформления перевода
                  </div>
                  <div className="how_it_works_official_links_item_text text">
                    С помощью телеграм бота, Вы можете оформить перевод за границу
                    в 2 клика без необходимости скачивания и регистрации.
                  </div>
                  <div className="how_it_works_official_links_item_text text">
                    Ссылка{'\u00A0'}на{'\u00A0'}бот: <a href="https://t.me/TransferDelivery_bot">@TransferDelivery_bot</a>
                  </div>
                  <form action="https://t.me/TransferDelivery_bot">
                    <button className="btn"><TelegramSvg />Открыть профиль</button>
                  </form>
                </div>
                <div className="how_it_works_official_links_item_img_wrapper official_link_3">

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt_container pb_container_line addresses_office_container">
          <h2 className="title mb_title">
            Адреса офисов
          </h2>
          <div className="addresses_office_wrapper">
            <div className="addresses_office_info_box">
              <div className="addresses_office_info_item addresses_office_info_item_1">
                <h3 className="title addresses_office_h3">Москва</h3>
                <h4 className="title addresses_office_h4">ул. Пресненская наб. д. 12</h4>
                <p className="text">
                  Режим работы офиса: с&nbsp;11:00 до&nbsp;20:00 по&nbsp;г. Москва
                </p>
                <p className="text">
                  Перед посещением офиса, необходимо получить пропуск. Чтобы оформить пропуск, необходимо предварительно связаться со&nbsp;<a className='addresses_office_link_support' href='https://t.me/TransferDeliverySupport'>службой поддержки</a>.
                </p>
                <p className="text mb_0">
                  Адреса офисов в&nbsp;других городах России уточняются у&nbsp;<a className='addresses_office_link_support' href='https://t.me/TransferDeliverySupport'>службой поддержки</a>.
                </p>
              </div>
            </div>
            <div className="addresses_office_img_box_wrapper">
              <div className="addresses_office_img_box">
                <div className="addresses_office_img_left_clmn"> </div>
                <div className="addresses_office_img_right_clmn_wrapper">
                  <div className="addresses_office_img_right_clmn addresses_office_img_right_clmn_item_1">
                  </div>
                  <div className="addresses_office_img_right_clmn addresses_office_img_right_clmn_item_2">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="addresses_office_yandex_box">
            <div className="addresses_office_yandex_box__wrapper">
              <div className="addresses_office_yandex_box__link">
                <img src="/assets/address_office/ya_maps___.svg" alt="img" className="addresses_office_img_yandex_logo_map" />
                <a href='https://yandex.ru/maps/-/CDutjFK~' className='addresses_office_yandex_link_a'>
                  <span className="addresses_office_yandex_link">
                    Открыть в Яндекс Картах
                  </span>
                </a>
              </div>

            </div>
          </div>
        </div>
        <div className="mt_container  addresses_office_partenrs_container mb_container">
          <div className="addresses_office_partenrs_wrapper_title mb_title">
            <h2 className="title addresses_office_partenrs_title">Офисы наших партнёров</h2>
            <p className="text ">
              Выдача наличных переводов <a className='addresses_office_link_support' href="https://transfer-dlivery.ru/">TransferDelivery</a> осуществляется в&nbsp;лучших обменниках, обладающие лицензией на&nbsp;работу в&nbsp;своей стране, высокой репутацией и&nbsp;доверием.
            </p>
          </div>
          <div className="addresses_office_wrapper pb_container_line">
            <div className="addresses_office_info_box">
              <div className="addresses_office_info_item addresses_office_info_item_1">
                <h3 className="title addresses_office_h3">Грузия</h3>
                <ul className='addresses_office_items'>
                  <li className='addresses_office_item'>
                    <h4 className="title addresses_office_h4">Адреса офисов в Тбилиси:</h4>
                    <p className="text">
                      Пр-т И. Чавчавадзе, 37<br></br>
                      Ул. Коте Абхази, 27<br></br>
                      Режим работы: с&nbsp;10:00 до&nbsp;21:00 по&nbsp;Грузии<br></br>
                    </p>
                  </li>
                  <li className='addresses_office_item'>
                    <h4 className="title addresses_office_h4">Адреса офисов в Батуми:</h4>
                    <p className="text">
                      ул. Зураба Горгиладзе, 957<br></br>
                      ул. К.&nbsp;Гамсахурдия, 277<br></br>
                      Режим работы: с&nbsp;10:00 до&nbsp;21:00 по&nbsp;Грузии<br></br>
                    </p>
                  </li>
                  <li className='addresses_office_item'>
                    <h4 className="title addresses_office_h4">Адрес офиса в Рустави:</h4>
                    <p className="text">
                      AUTOPAPA авторынок<br></br>
                      Режим работы: с 11:00 до 21:00 по Грузии<br></br>
                    </p>
                  </li>
                </ul>

              </div>
            </div>
            <div className="addresses_office_img_box_wrapper">
              <div className="addresses_office_img_box">
                <div className="addresses_office_img_left_clmn addresses_office_img_left_clmn_georgia"> </div>
                <div className="addresses_office_img_right_clmn_wrapper">
                  <div className="addresses_office_img_right_clmn addresses_office_img_right_clmn_item_1 addresses_office_img_right_clmn_item_1_georgia">
                  </div>
                  <div className="addresses_office_img_right_clmn addresses_office_img_right_clmn_item_2 addresses_office_img_right_clmn_item_2_georgia">
                  </div>
                </div>
              </div>
              <div className="addresses_office_google_box">
                <div className="addresses_office_google_box__wrapper">
                  <img src="/assets/address_office/google_map.png" alt="img" className="addresses_office_img_google_logo_map" />
                  <a href='https://maps.app.goo.gl/3baA7f11KQFAoiPd9' className='addresses_office_google_link_a'>
                    <span className="addresses_office_google_link">
                      Открыть в Google maps
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="addresses_office_wrapper mt_container">
            <div className="addresses_office_info_box">
              <div className="addresses_office_info_item addresses_office_info_item_1">
                <h3 className="title addresses_office_h3">Дубаи</h3>
                <ul>
                  <li>
                    <h4 className="title addresses_office_h4">The Binary by OMNIYAT</h4>
                    <p className="text">
                      Режим работы офиса: с&nbsp;11:00 до&nbsp;18:30 по&nbsp;Дубаю
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      Перед посещением офиса, необходимо предварительно обсудить детали перевода со&nbsp;<a className='addresses_office_link_support' href='https://t.me/TransferDeliverySupport'>службой поддержки</a>.
                    </p>
                  </li>
                  <li>
                    <p className="text">
                      Адреса офисов наших партнёров в других странах уточняются у&nbsp;<a className='addresses_office_link_support' href='https://t.me/TransferDeliverySupport'>службой поддержки</a>.
                    </p>
                  </li>
                </ul>

              </div>
            </div>
            <div className="addresses_office_img_box_wrapper">
              <div className="addresses_office_img_box">
                <div className="addresses_office_img_left_clmn addresses_office_img_left_clmn_dubai"> </div>
                <div className="addresses_office_img_right_clmn_wrapper">
                  <div className="addresses_office_img_right_clmn addresses_office_img_right_clmn_item_1 addresses_office_img_right_clmn_item_1_dubai">
                  </div>
                  <div className="addresses_office_img_right_clmn addresses_office_img_right_clmn_item_2 addresses_office_img_right_clmn_item_2_dubai">
                  </div>
                </div>
              </div>
              <div className="addresses_office_google_box">
                <div className="addresses_office_google_box__wrapper">
                  <img src="/assets/address_office/google_map.png" alt="img" className="addresses_office_img_google_logo_map" />
                  <a href='https://maps.app.goo.gl/RXREYqsRmZdNMWoC8' className='addresses_office_google_link_a'>
                    <span className="addresses_office_google_link">
                      Открыть в Google maps
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </Layout>
  );
};

export default HowItWorks;
