import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import TelegramSvg from "../../elements/TelegramSvg/TelegramSvg";
import ArrowSvg from "../../elements/ArrowSvg/ArrowSvg";
import {
  createTransaction,
  getCountries,
  getCountriesProperties,
  getCurrency,
  getTariff,
} from "../../http/transactionAPI";
import SelectCountry from "../../elements/SelectCountry/SelectCountry";
import SelectCurrency from "../../elements/SelectCurrency/SelectCurrency";
import {
  Countries,
  Currencies,
  IsOpenCountry,
  IsOpenCurrency,
  IsOpenMessenger,
  IsOpenMethod,
  IsOpenSendCountry,
  IsOpenSendCurrency,
  Messenger,
  Methods,
  SendCountries,
  SendCurrencies,
} from "../..";
import { ToastContainer, toast } from "react-toastify";
import CheckArrowSvg from "../../elements/CheckArrowSvg/CheckArrowSvg";
import SelectSendCountry from "../../elements/SelectSendCountry/SelectSendCountry";
import SelectMethod from "../../elements/SelectMethod/SelectMethod";
import SelectSendCurrency from "../../elements/SelectSendCurrency/SelectSendCurrency";
import SelectMessenger from "../../elements/SelectMessenger/SelectMessenger";

const MainPageModuleOne = () => {
  const [country, setCountry] = useState("Выберите страну получения");
  const [method, setMethod] = useState("Выберите способ получения");
  const refOne = useRef();
  const refTwo = useRef();


  const [id, setId] = useState();
  const ref = useRef()
  const refNick = useRef()
  const refMob = useRef()
  const refNickMob = useRef()
  const [currency, setCurrency] = useState("USD");
  const [messenger, setMessenger] = useState("Telegram");

  const [isOpenCurrency, setIsOpenCurrency] = useState(false);
  const [isOpenCountry, setIsOpenCountry] = useState(false);
  const [sendCountry, setSendCountry] = useState("Выберите страну отправления");
  const [sendCurrency, setSendCurrency] = useState("Валюта получения");
  const [isOpenSendCurrency, setIsOpenSendCurrency] = useState(false);
  const [isOpenSendCountry, setIsOpenSendCountry] = useState(false);
  const [isOpenMethod, setIsOpenMethod] = useState(false);
  const [isOpenMessenger, setIsOpenMessenger] = useState(false);

  const [minSumma, setMinSumma] = useState();
  const [mess, setMess] = useState();
  const [spinner, setSpinner] = useState(false);
  const [paySumma, setPaySumma] = useState("");
  const [nick, setNick] = useState("");
  const [redCountry, setRedCountry] = useState(false);
  const [redSendCountry, setRedSendCountry] = useState(false);
  const [redCurrency, setRedCurrency] = useState(false);
  const [redMethod, setRedMethod] = useState(false);




  useEffect(() => {
    refOne.current.style.display = "block";
    refTwo.current.style.display = "none";
  }, []);

  useEffect(() => {
    if (sendCountry !== "Выберите страну отправления")
      getCountriesProperties(sendCountry.title).then((response) => {
        response.country.currencies.forEach((item) => {
          if (item.title === currency) {
            setMinSumma(item.min_value_transaction);
          }
        });
      });
  }, [currency, sendCountry]);

  return (
    <Countries.Provider value={[country, setCountry]}>
      <Methods.Provider value={[method, setMethod]}>
        <Messenger.Provider value={[messenger, setMessenger]}>
          <IsOpenMessenger.Provider
            value={[isOpenMessenger, setIsOpenMessenger]}
          >
            <Currencies.Provider value={[currency, setCurrency]}>
              <IsOpenCurrency.Provider
                value={[isOpenCurrency, setIsOpenCurrency]}
              >
                <IsOpenCountry.Provider
                  value={[isOpenCountry, setIsOpenCountry]}
                >
                  <SendCountries.Provider value={[sendCountry, setSendCountry]}>
                    <SendCurrencies.Provider
                      value={[sendCurrency, setSendCurrency]}
                    >
                      <IsOpenSendCurrency.Provider
                        value={[isOpenSendCurrency, setIsOpenSendCurrency]}
                      >
                        <IsOpenSendCountry.Provider
                          value={[isOpenSendCountry, setIsOpenSendCountry]}
                        >
                          <IsOpenMethod.Provider
                            value={[isOpenMethod, setIsOpenMethod]}
                          >
                            <div className="module_one mb_container">
                              <div className="module_one_row">
                                <div className="module_one_section_one">
                                  <div className="main_title">
                                    <div className="module_one_title_item main_title">
                                      <span>Международные </span>
                                      <span>переводы</span>
                                      <span className="module_one_telegram">
                                        {" "}
                                        TransferDelivery
                                      </span>
                                    </div>
                                  </div>
                                  <span className="text">
                                    Переводи денежные средства онлайн
                                    за&nbsp;границу в&nbsp;несколько кликов
                                    по&nbsp;всему Миру
                                  </span>
                                  <form action="https://t.me/TransferDelivery_bot">
                                    <button className="btn">
                                      <TelegramSvg />
                                      <span>Отправить перевод</span>
                                    </button>
                                  </form>
                                </div>

                                <div
                                  ref={refOne}
                                  className="module_one_section_two"
                                >
                                  <h3 className="module_one_section_two_title title">
                                    Оформите перевод онлайн
                                  </h3>
                                  <div className="module_one_section_two_body">
                                    <div className="module_one_section_two_body_left">
                                      <SelectSendCountry redSendCountry={redSendCountry} />
                                      <div className="second_block">
                                        <div
                                          ref={ref}

                                         className="pay_summa">
                                          <input
                                            min={0}
                                            className="pay_summa_input"
                                            type="number"
                                            value={paySumma}
                                            onChange={(e) => {
                                              setPaySumma(e.target.value);
                                              if (e.target.value !==minSumma) {
                                            ref.current.style.border = 'none'

                                              }
                                            }}
                                            placeholder="Сумма к отправке"
                                          />
                                          <span className="pay_summa_rubl">
                                            <SelectCurrency />
                                          </span>
                                        </div>
                                        <SelectSendCurrency redSendCurrenxy={redCurrency} />
                                      </div>
                                      <div 
                                      ref={refNick}
                                      className="nickname">
                                        {messenger == 'Telegram' ? <input
                                          type="text"
                                          value={nick}
                                          onChange={(e) => {
                                            setNick(e.target.value);
                                            if (e.target.value !== '') {
                                              refNick.current.style.border = 'none'
                                                }
                                          }}
                                          placeholder="Ваш никнейм в Telegram*"
                                        />: <input
                                        type="text"
                                        value={nick}
                                        onChange={(e) => {
                                          setNick(e.target.value);
                                        }}
                                        placeholder="Введите номер телефона*"
                                      />}
                                        <div className="pay_summa_rubl">
                                            {/* <TelegramSvg /> */}
                                            <SelectMessenger />

                                        </div>
                                      </div>
                                    </div>
                                    <div className="module_one_section_two_body_right">
                                      <SelectCountry redCountry={redCountry} />
                                      <SelectMethod redMethod={redMethod} />
                                      <button
                                        className="btn"
                                        onClick={(e) => {
                                          if (
                                            nick === "" ||
                                            paySumma === "" ||
                                            method ===
                                              "Выберите способ получения" ||
                                            sendCountry ===
                                              "Выберите страну отправления" ||
                                            country ===
                                              "Выберите страну получения" ||
                                            currency === "Валюта получения"
                                          ) {
                                            e.preventDefault();

                                            toast.error(`Заполните поля`, {
                                              position:
                                                toast.POSITION.BOTTOM_RIGHT,
                                            });
                                          } 
                                          if (paySumma < minSumma) {
                                            ref.current.style.border = '3px solid green'
                                            setPaySumma(minSumma);
                                            toast.error(
                                              `Сумма отправки не меньше ${minSumma}`,
                                              {
                                                position:
                                                  toast.POSITION.BOTTOM_RIGHT,
                                              }
                                            );
                                          } 
                                          if (nick === "") {
                                            refNick.current.style.border = '3px solid red'
                                          }
                                          if (paySumma === "") {
                                            ref.current.style.border = '3px solid red'
                                          }
                                          if (method ===
                                            "Выберите способ получения") {
                                              setRedMethod(true)
                                            } else {
                                              setRedMethod(false)
                                            }
                                            if (sendCountry ===
                                              "Выберите страну отправления") {
                                                setRedSendCountry(true)
                                              } else {
                                                setRedSendCountry(false)
                                              }
                                              if (country ===
                                                "Выберите страну получения") {
                                                  setRedCountry(true)
                                                } else {
                                                  setRedCountry(false)
                                                }
                                                if ( currency !== "Валюта получения") {
                                                  setRedCurrency(true)
                                                } else {
                                                  setRedCurrency(false)
                                                  
                                                }
                                          if (
                                            nick !== "" &&
                                            paySumma !== "" &&
                                            paySumma >= minSumma &&
                                            method !==
                                              "Выберите способ получения" &&
                                            sendCountry !==
                                              "Выберите страну отправления" &&
                                            country !==
                                              "Выберите страну получения" &&
                                            currency !== "Валюта получения"
                                          ){
                                            createTransaction(
                                             messenger,
                                              sendCountry.title_rus,
                                              country.title_rus,
                                              paySumma,
                                              currency,
                                              sendCurrency,
                                              method,
                                              nick
                                            ).then((response) => {
                                              setId(response.id);
                                            });
                                            refOne.current.style.display =
                                              "none";
                                            refTwo.current.style.display =
                                              "block";

                                          }
                                        }}
                                      >
                                        Отправить перевод
                                      </button>
                                    </div>
                                  </div>
                                  <div className="module_one_section_two_body_mobile">
                                      <SelectSendCountry redSendCountry={redSendCountry} />
                                        <div
                                          ref={refMob}

                                         className="pay_summa">
                                          <input
                                            min={0}
                                            className="pay_summa_input"
                                            type="number"
                                            value={paySumma}
                                            onChange={(e) => {
                                              setPaySumma(e.target.value);
                                              if (e.target.value !==minSumma) {
                                            refMob.current.style.border = 'none'

                                              }
                                            }}
                                            placeholder="Сумма к отправке"
                                          />
                                          <span className="pay_summa_rubl">
                                            <SelectCurrency />
                                          </span>
                                        </div>
                                      <SelectCountry redCountry={redCountry} />

                                        <SelectSendCurrency redSendCurrenxy={redCurrency} />
                                      <SelectMethod redMethod={redMethod} />

                                      <div 
                                      ref={refNickMob}
                                      className="nickname">
                                        {messenger == 'Telegram' ? <input
                                          type="text"
                                          value={nick}
                                          onChange={(e) => {
                                            setNick(e.target.value);
                                            if (e.target.value !== '') {
                                              refNickMob.current.style.border = 'none'
  
                                                }
                                          }}
                                          placeholder="Ваш никнейм в Telegram*"
                                        />: <input
                                        type="text"
                                        value={nick}
                                        onChange={(e) => {
                                          setNick(e.target.value);
                                          if (e.target.value !== '') {
                                            refNickMob.current.style.border = 'none'

                                              }
                                        }}
                                        placeholder="Введите номер телефона*"
                                      />}
                                        <div className="pay_summa_rubl">
                                            {/* <TelegramSvg /> */}
                                            <SelectMessenger />

                                        </div>
                                      </div>
                                      <button
                                        className="btn"
                                        onClick={(e) => {
                                          if (
                                            nick === "" ||
                                            paySumma === "" ||
                                            method ===
                                              "Выберите способ получения" ||
                                            sendCountry ===
                                              "Выберите страну отправления" ||
                                            country ===
                                              "Выберите страну получения" ||
                                            currency === "Валюта получения"
                                          ) {
                                            e.preventDefault();

                                            toast.error(`Заполните поля`, {
                                              position:
                                                toast.POSITION.BOTTOM_RIGHT,
                                            });
                                          } 
                                          if (paySumma < minSumma) {
                                            refMob.current.style.border = '3px solid green'
                                            setPaySumma(minSumma);
                                            toast.error(
                                              `Сумма отправки не меньше ${minSumma}`,
                                              {
                                                position:
                                                  toast.POSITION.BOTTOM_RIGHT,
                                              }
                                            );
                                          } 
                                          if (nick === "") {
                                            refNickMob.current.style.border = '3px solid red'
                                          }
                                          if (paySumma === "") {
                                            refMob.current.style.border = '3px solid red'
                                          }
                                          if (method ===
                                            "Выберите способ получения") {
                                              setRedMethod(true)
                                            } else {
                                              setRedMethod(false)
                                            }
                                            if (sendCountry ===
                                              "Выберите страну отправления") {
                                                setRedSendCountry(true)
                                              } else {
                                                setRedSendCountry(false)
                                              }
                                              if (country ===
                                                "Выберите страну получения") {
                                                  setRedCountry(true)
                                                } else {
                                                  setRedCountry(false)
                                                }
                                                if ( currency !== "Валюта получения") {
                                                  setRedCurrency(true)
                                                } else {
                                                  setRedCurrency(false)
                                                  
                                                }
                                          if (
                                            nick !== "" &&
                                            paySumma !== "" &&
                                            paySumma >= minSumma &&
                                            method !==
                                              "Выберите способ получения" &&
                                            sendCountry !==
                                              "Выберите страну отправления" &&
                                            country !==
                                              "Выберите страну получения" &&
                                            currency !== "Валюта получения"
                                          ){
                                            createTransaction(
                                             messenger,
                                              sendCountry.title_rus,
                                              country.title_rus,
                                              paySumma,
                                              currency,
                                              sendCurrency,
                                              method,
                                              nick
                                            ).then((response) => {
                                              setId(response.id);
                                            });
                                            refOne.current.style.display =
                                              "none";
                                            refTwo.current.style.display =
                                              "block";

                                          }
                                        }}
                                      >
                                        Отправить перевод
                                      </button>
                                  </div>
                                  <div className="module_one_section_two_footer">
                                    <div className="text module_one_section_two_footer_left">
                                      После оформления перевода, служба
                                      поддержки свяжется с Вами для его
                                      подтверждения
                                    </div>
                                    <div className="text module_one_section_two_footer_right">
                                      Нажимая кнопку, Вы соглашаетесь на
                                      обработку персональных данных
                                    </div>
                                  </div>
                                </div>
                                <div
                                  ref={refTwo}
                                  className="module_one_section_two"
                                >
                                  <div className="module_one_section_two_end">
                                    <div className="module_one_section_two_left_wrapper">
                                      <h2 className="module_one_section_two_left_title title">
                                        Перевод №{" "}
                                        {spinner ? (
                                          <span className="spinner"></span>
                                        ) : (
                                          <span className="id">{id}</span>
                                        )}
                                      </h2>
                                      <p className="module_one_section_two_left_text text">
                                        Пожалуйста, ожидайте подтверждения.
                                        Скоро с Вами свяжется служба поддержки
                                        для уточнения условий перевода.
                                      </p>
                                    </div>
                                    <div className="module_one_section_two_right_wrapper">
                                      <ul className="module_one_section_two_right_items">
                                        <li className="module_one_section_two_right_item">
                                          <h3 className="module_one_section_two_right_item_title title">
                                            1. Подтвердите перевод
                                          </h3>
                                          <p className="module_one_section_two_right_item_text text">
                                            Служба поддержки свяжется с Вами и
                                            согласует условия и курс перевода
                                          </p>
                                        </li>
                                        <li className="module_one_section_two_right_item">
                                          <h3 className="module_one_section_two_right_item_title title">
                                            2. Оплатите перевод
                                          </h3>
                                          <p className="module_one_section_two_right_item_text text">
                                            Служба поддержки отправит реквизиты
                                            для оплаты перевода
                                          </p>
                                        </li>
                                        <li className="module_one_section_two_right_item">
                                          <h3 className="module_one_section_two_right_item_title title">
                                            3. Получите перевод
                                          </h3>
                                          <p className="module_one_section_two_right_item_text text">
                                            Моментально получите деньги
                                            наличными или на зарубежную карту в
                                            течение 10 минут
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </IsOpenMethod.Provider>
                        </IsOpenSendCountry.Provider>
                      </IsOpenSendCurrency.Provider>
                    </SendCurrencies.Provider>
                  </SendCountries.Provider>
                </IsOpenCountry.Provider>
              </IsOpenCurrency.Provider>
            </Currencies.Provider>
          </IsOpenMessenger.Provider>
        </Messenger.Provider>
      </Methods.Provider>
    </Countries.Provider>
  );
};

export default MainPageModuleOne;
