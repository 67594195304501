import React, { useState } from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import TelegramSvg from "../../elements/TelegramSvg/TelegramSvg";
import EnvelopeSvg from "../../elements/EnvelopeSvg/EnvelopeSvg";
import EnvelopeTelephoneSvg from "../../elements/EnvelopeTelephoneSvg/EnvelopeTelephoneSvg";

import { ToastContainer, toast } from "react-toastify";

const Footer = () => {
  const navigate = useNavigate();
  const [copy, setCopy] = useState(false);
  const [copy2, setCopy2] = useState(false);
  return (
    <div className="footer_container">
      <div className="footer_mobile">
        <div className="footer_mobile_item_one">
          <div className="footer_mobile_item_one_img">
            <img
              onClick={() => {
                navigate("/");
              }}
              src="/assets/logo-footer.svg"
            />
          </div>
          <div className="footer_mobile_right_block_svg">
            <a href="https://t.me/TransferDelivery_bot">
              <TelegramSvg />
            </a>

            <div
              onClick={() => {
                let inp = document.createElement("input");
                inp.value = "info.transferdelivery@gmail.com";
                document.body.appendChild(inp);
                inp.select();
                document.execCommand("copy");
                document.body.removeChild(inp);
                setCopy(true);
                setCopy2(false);
                toast.info("Почта скопирована", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                })
              }}
            >
              <EnvelopeSvg />
            </div>

          </div>
        </div>
        <div className="footer_mobile_item_two">
          <span
            onClick={() => {
              navigate("/how-it-works");
            }}
          >
            Как это работает?
          </span>
          <span
            onClick={() => {
              navigate("/blog");
            }}
          >
            Блог
          </span>
          <span
            onClick={() => {
              navigate("/otzivi");
            }}
          >
            Отзывы
          </span>
          <span
            onClick={() => {
              navigate("/contacts");
            }}
          >
            Контакты
          </span>
        </div>
        <div className="footer_mobile_item_three">
          <span
            onClick={() => {
              navigate("/privacy-police");
            }}
          >
            Политика конфиденциальности
          </span>
          <span>© 2024 TransferDelivery</span>
        </div>
        <div className="footer_mobile_item_three">
          <span
            onClick={() => {
              navigate("/eula");
            }}
          >
            Пользовательское соглашение
          </span>
          <span>All Rights Reserved</span>
        </div>
      </div>
      <div className="footer">
        <div className="footer_up">
          <div className="footer_up_left">
            <img
              onClick={() => {
                navigate("/");
              }}
              src="/assets/logo-footer.svg"
            />
          </div>
          <div className="footer_up_right">
            <span
              onClick={() => {
                navigate("/how-it-works");
              }}
            >
              Как это работает?
            </span>
            <span
              onClick={() => {
                navigate("/blog");
              }}
            >
              Блог
            </span>
            <a href="https://t.me/TransferDelivery_bot">
              <TelegramSvg />
            </a>
            {copy ? (
              <div>info.transferdelivery@gmail.com</div>
            ) : (
              <div
                onClick={() => {
                  let inp = document.createElement("input");
                  inp.value = "info.transferdelivery@gmail.com";
                  document.body.appendChild(inp);
                  inp.select();
                  document.execCommand("copy");
                  document.body.removeChild(inp);
                  setCopy(true);
                  setCopy2(false);
                  toast.info("Почта скопирована", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  })
                }}
              >
                <EnvelopeSvg />
              </div>
            )}

          </div>
        </div>
        <div className="footer_down">
          <div className="footer_down_left">
            <span
              onClick={() => {
                navigate("/privacy-police");
              }}
            >
              Политика конфиденциальности
            </span>
            <span
              onClick={() => {
                navigate("/eula");
              }}
            >
              Пользовательское соглашение
            </span>
          </div>
          <div className="footer_down_right">
            <span>© 2024 TransferDelivery</span>
            <span>All Rights Reserved</span>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Footer;
