import React from "react";

const SliderArrowSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <circle cx="30" cy="30" r="29.5" fill="white" stroke="#666667" />
      <path
        d="M28.8767 15.6301C29.0445 15.8291 29.1776 16.0656 29.2684 16.326C29.3592 16.5864 29.406 16.8655 29.406 17.1475C29.406 17.4294 29.3592 17.7086 29.2684 17.969C29.1776 18.2294 29.0445 18.4659 28.8767 18.6649L21.1547 27.8568H43.2004C43.6777 27.8568 44.1354 28.0825 44.4729 28.4842C44.8104 28.886 45 29.4308 45 29.999C45 30.5671 44.8104 31.112 44.4729 31.5138C44.1354 31.9155 43.6777 32.1412 43.2004 32.1412H21.1547L28.8767 41.3367C29.2148 41.7391 29.4047 42.2849 29.4047 42.8541C29.4047 43.4232 29.2148 43.969 28.8767 44.3715C28.5386 44.7739 28.0801 45 27.6019 45C27.1238 45 26.6653 44.7739 26.3272 44.3715L15.5293 31.5182C15.3615 31.3192 15.2284 31.0827 15.1376 30.8223C15.0468 30.5619 15 30.2827 15 30.0008C15 29.7188 15.0468 29.4397 15.1376 29.1793C15.2284 28.9189 15.3615 28.6824 15.5293 28.4834L26.3272 15.6301C26.4944 15.4304 26.693 15.2719 26.9118 15.1638C27.1305 15.0557 27.3651 15 27.6019 15C27.8388 15 28.0733 15.0557 28.2921 15.1638C28.5108 15.2719 28.7095 15.4304 28.8767 15.6301Z"
        fill="#666667"
      />
    </svg>
  );
};

export default SliderArrowSvg;
