import React from 'react';
import "./index.css";
import Layout from '../../layout/Layout';
import { useNavigate } from 'react-router-dom';


const Blog = () => {
    const navigate = useNavigate()

    return (
        <Layout>
            <div className='blog'>
                <div className='blog_container pd_container'>
                    <h2 className='title'>Блог</h2>
                    <div className='blog_block'>
                        <div
                            onClick={() => navigate('/blog-item-one')}
                            className='blog_block_item_1'>
                            <span>4 декабря</span>
                            <img src='/assets/pages/blog_items/blogimage.png' />
                            <p className='text'>В чем проблема SWIFT-переводов и почему растет недоверие к банковской системе?</p>
                        </div>
                        <div
                            onClick={() => navigate('/blog-item-two')}
                            className='blog_block_item_2'>
                            <span>25 ноября 2023</span>
                            <img src='/assets/pages/blog_items/blogimage2.jpg' />
                            <p className='text'>Переводы за&nbsp;рубеж с&nbsp;помощью сервиса TransferDelivery</p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>

    );
};

export default Blog;