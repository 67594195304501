import React from "react";

const Attention = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="41"
      viewBox="0 0 8 41"
      fill="none"
    >
      <path
        d="M0.99474 22.8102C1.14019 24.4494 1.38302 25.6697 1.72261 26.4709C2.06161 27.2716 2.66927 27.6735 3.5438 27.6735C3.70789 27.6735 3.85515 27.6441 4.0012 27.6139C4.14846 27.6441 4.29391 27.6735 4.4574 27.6735C5.33073 27.6735 5.93719 27.2716 6.27859 26.4709C6.61698 25.6697 6.8604 24.4494 7.00526 22.8102L7.78182 10.1562C7.92727 7.68954 8 5.91876 8 4.84517C8 3.38272 7.64838 2.24169 6.94635 1.42406C6.24132 0.605767 5.3151 0.195312 4.16709 0.195312C4.10699 0.195312 4.06071 0.210369 4.0024 0.212988C3.9441 0.210369 3.89722 0.195312 3.83771 0.195312C2.6885 0.195312 1.76349 0.605112 1.05905 1.42406C0.355822 2.24235 0.00420734 3.38337 0.00420734 4.84582C0.00420734 5.92007 0.077535 7.6902 0.222389 10.1569L0.998347 22.8102M4.02464 32.2946C2.9133 32.2946 1.96424 32.6788 1.17806 33.4428C0.393087 34.2113 0 35.1396 0 36.2302C0 37.4662 0.397295 38.433 1.19068 39.1361C1.98648 39.8405 2.9133 40.1953 3.97055 40.1953C5.04883 40.1953 5.98828 39.8457 6.79188 39.1512C7.59549 38.4553 7.99399 37.4819 7.99399 36.2295C7.99399 35.1389 7.60931 34.2107 6.84177 33.4421C6.07483 32.6788 5.13539 32.2946 4.02224 32.2946"
        fill="#BE212F"
      />
    </svg>
  );
};

export default Attention;
